import axios from 'axios';
import { INursery } from '../../../@types/model/masterData/nursery/nursery';
import { INurseryUpsert } from '../../../@types/model/masterData/nursery/nurseryUpsert';
import { UpsertTypeEnum } from '../../../@types/enum/upsertType';

export default class NurseryHttpService {
    public static getList = async (lastSyncedTimeUnix : number, signal ?: AbortSignal) : Promise<Array<INursery>> => {
        const result = await axios.get(`${API_URL}/v1/MasterData/Nursery/GetList`, {
            params: {
                lastSyncedTimeUnix
            },
            signal
        });

        return result.data;
    }

    public static upsert = async (upsert : INurseryUpsert, type : UpsertTypeEnum) : Promise<INursery> => {
        const result = await axios.post(`${API_URL}/v1/MasterData/Nursery/${type}`, upsert);

        return result.data;
    }

    public static delete = async (id : number) : Promise<void> => {
        return await axios.delete(`${API_URL}/v1/MasterData/Nursery/Delete`, {
            params: { id },
        });
    }
}
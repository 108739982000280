import axios from 'axios';
import { IProject } from '../../../@types/model/masterData/project/project';
import { IProjectUpsert } from '../../../@types/model/masterData/project/projectUpsert';
import { IProjectBlockBridgeUpsert } from '../../../@types/model/masterData/projectBlockBridge/projectBlockBridgeUpsert';
import { UpsertTypeEnum } from '../../../@types/enum/upsertType';

export default class ProjectHttpService {
    public static getList = async (lastSyncedTimeUnix : number, signal ?: AbortSignal) : Promise<Array<IProject>> => {
        const result = await axios.get(`${API_URL}/v1/MasterData/Project/GetList`, {
            params: {
                lastSyncedTimeUnix
            },
            signal
        });

        return result.data;
    }

    public static upsert = async (upsert : IProjectUpsert, type : UpsertTypeEnum) : Promise<IProject> => {
        const result = await axios.post(`${API_URL}/v1/MasterData/Project/${type}`, upsert);

        return result.data;
    };

    public static projectBlockBridgeUpsert = async (upsert : Array<IProjectBlockBridgeUpsert>) : Promise<IProject> => {
        const result = await axios.post(`${API_URL}/v1/MasterData/Project/UpsertProjectBlockBridge`, upsert);

        return result.data;
    };

    public static delete = async (id : number) : Promise<void> => {
        return await axios.delete(`${API_URL}/v1/MasterData/Project/Delete`, {
            params: { id },
        });
    };
}
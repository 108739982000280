import React, { useEffect, useMemo } from 'react';
import defaultTheme from './style/materialTheme';
import SnackbarNotifier from './modules/root/SnackbarNotifier';
import { useAppDispatch, useAppSelector } from './@types/redux';
import * as localStorageService from './service/localStorageService';
import './style/app.scss';
import { initializeInterceptor } from './service/http';
import { ThemeProvider } from '@mui/material';
import AuthActions from './store/auth/actions';
import GeneralThunk from './store/general/thunk';
import AuthThunk from './store/auth/thunk';
import useFontAwesome from './hooks/useFontAwesome';
import { IUserToken } from './@types/model/auth/userToken/userToken';
import { useLocation } from 'react-router-dom';
import PasswordRecovery from './modules/root/PasswordRecovery';
import { IPasswordRecoveryFormValues } from './@types/model/auth/password/passwordRecoveryFormValues';
import PasswordRecoveryModelHelper from './@types/model/auth/password/passwordRecoveryModelHelper';
import { Loader, Login, IGoogleLoginSuccess, IGoogleLoginError } from '@zz2/zz2-ui';
import { VERSION } from './version';
import navActions from './store/nav/actions';
import NavFrame from './modules/root/NavFrame';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { QUERY_DATA_STALE_TIME } from './appConstants';
import EnvHelper from './service/helper/envHelper';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
// import { OptionType } from './@types/model/optionType';
// import DataActions from './store/data/actions';

const App = () : JSX.Element => {
    useFontAwesome();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const isLoggingIn = useAppSelector<boolean>(x => x.auth.isLoggingIn);
    const isLoggingOut = useAppSelector<boolean>(x => x.auth.isLoggingOut);
    const session = useAppSelector<null | IUserToken>(x => x.auth.session);
    const isMasterDataSyncRunning = useAppSelector(x => x.data.masterDataSyncIsLoading);
    const isNavDrawerOpen = useAppSelector(x => x.general.isNavDrawerOpen);
    const showRecoveryScreen = location.pathname === '/reset-password';
    
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: { staleTime: QUERY_DATA_STALE_TIME },
            mutations: {}
        }
    });


    /*================================================================================================================
     *                                                  Effects
     * ==============================================================================================================*/

    const ReactQueryDevtoolsProduction = React.lazy(() =>
        import('@tanstack/react-query-devtools/build/lib/index.prod.js').then(
            (d) => ({
                default: d.ReactQueryDevtools,
            }),
        ),
    );

    useEffect(() => {
        dispatch(AuthActions.setLoggingIn(true));
        localStorageService.onSessionChanged(async (user) => {
            if (user) {

                /* Logged In */
                initializeInterceptor(user.token, onUnauthenticated, onUnauthorized, onConnectionError);
                dispatch(AuthActions.setSession(user));
                dispatch(AuthActions.setLoggedIn(true));
                dispatch(AuthActions.setLoggingIn(false));

            } else {

                /* Logged Out or Not yet logged in */
                initializeInterceptor('', onUnauthenticated, onUnauthorized, onConnectionError);
                dispatch(AuthActions.setLoggedIn(false));
                dispatch(AuthActions.setSession(null));
                dispatch(AuthActions.setLoggingIn(false));
            }
        });

    }, []);

    // useEffect(() => {
    //     if (session?.user?.divisionIds !== null && session?.user?.divisionIds.length === 1) {
    //         const selectedDivisions : Array<IOptionType> = session.user.userDivisions
    //             .filter(x => x.isActive && !!x.division?.isActive)
    //             .map(OptionType.fromDataModel);
    //         localStorageService.setUserSelectedDivisionsLocalStorage(selectedDivisions);
    //         dispatch(DataActions.setSelectedUserDivisionIds(session.user.divisionIds));
    //     } else {
    //         const selectedDivisions = localStorageService.getUserSelectedDivisionsLocalStorage();

    //         if (selectedDivisions.length > 0) {
    //             dispatch(DataActions.setSelectedUserDivisionIds(selectedDivisions.map(x => Number(x.value))));
    //         }
    //     }
    //     if (session?.user?.departmentIds !== null && session?.user?.departmentIds.length === 1) {
    //         const selectedDepartments = session.user.userDepartments
    //             .filter(x => x.isActive && !!x.department?.isActive)
    //             .map(OptionType.fromDataModel);
    //         localStorageService.setUserSelectedDepartmentsLocalStorage(selectedDepartments);
    //         dispatch(DataActions.setSelectedUserDepartmentIds(session.user.departmentIds));
    //     } else {
    //         const selectedDepartments = localStorageService.getUserSelectedDepartmentsLocalStorage();

    //         if (selectedDepartments.length > 0) {
    //             dispatch(DataActions.setSelectedUserDepartmentIds(selectedDepartments.map(x => Number(x.value))));
    //         }
    //     }
    // }, [session, session?.user, session?.user?.divisionIds, session?.user?.departmentIds]);

    /*================================================================================================================
     *                                                  Handler Methods
     * ==============================================================================================================*/

    const onUnauthenticated = (error : any) : void => {
        initializeInterceptor('', onUnauthenticated, onUnauthorized, onConnectionError);
        dispatch(GeneralThunk.showErrorSnackbar({
            defaultMessage: 'Unauthenticated.',
            ex: error,
        }));

        dispatch(AuthThunk.logOut());
    };

    const onUnauthorized = (error : any) : void => {
        dispatch(GeneralThunk.showErrorSnackbar({
            defaultMessage: 'Insufficient rights.',
            ex: error,
        }));
    };

    const onConnectionError = () : void => {
        dispatch(GeneralThunk.showErrorSnackbar({
            defaultMessage: 'Connection error.',
        }));
    };

    const manualSignUp = async (employeeNumber : string, password : string) : Promise<void> => {
        dispatch(AuthThunk.signUp({ employeeNumber, password }));
    };

    const manualLogin = async (username : string, password : string) : Promise<void> => {
        dispatch(AuthThunk.manualLogIn({ username, password }));
    };

    const requestForgottenPassword = async (recoveryEmailOrUsername : string) : Promise<void> => {
        await dispatch(AuthThunk.requestForgottenPassword({ emailOrUsername: recoveryEmailOrUsername }));
    };

    const onGoogleLogInSuccess = async (response : IGoogleLoginSuccess) : Promise<void> => {
        const res = await dispatch(AuthThunk.googleLogIn({
            code: response.code,
        })).unwrap();

        if (res) {
            navActions.navReplace('/home');
        }
    };

    const onGoogleLogInFailure = (response : IGoogleLoginError) : void => {
        dispatch(GeneralThunk.showErrorSnackbar({
            defaultMessage: response.error ?? 'Login Error',
            ex: response,
        }));
        dispatch(AuthActions.setLoggingIn(false));
    };


    /*================================================================================================================
     *                                                  Form Values
     * ==============================================================================================================*/


    const getInitialPasswordFormValues = useMemo<IPasswordRecoveryFormValues>(() => {
        return PasswordRecoveryModelHelper.createFormValues();
    }, []);


    /*================================================================================================================
     *                                                  Render Methods
     * ==============================================================================================================*/

    const renderApp = useMemo<React.ReactElement>(() => {
        if (isLoggingIn || isLoggingOut) {
            return <Loader/>;
        }
        
        if (session) {
            return (
                <NavFrame />
            );
        }

        return showRecoveryScreen 
            ? <PasswordRecovery initialFormValues={getInitialPasswordFormValues}/> 
            : <Login
                env={EnvHelper.getEnvName()}
                version={VERSION.version}
                isLoading={isLoggingIn}
                isLoggingIn={isLoggingIn}
                manualLogIn={manualLogin}
                enableSignUp
                manualSignUp={manualSignUp}
                requestForgottenPassword={requestForgottenPassword}
                onGoogleSignInSuccess={onGoogleLogInSuccess}
                onGoogleSignInFailure={onGoogleLogInFailure}
            />;

    }, [isLoggingIn, isLoggingOut, session, isMasterDataSyncRunning, showRecoveryScreen, isNavDrawerOpen]);

    return (
        <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={defaultTheme}>
                {renderApp}
                <SnackbarNotifier />
            </ThemeProvider>
            <ReactQueryDevtools initialIsOpen={false} />
            <React.Suspense fallback={null}>
                {
                    EnvHelper.isDevelopmentQa() &&
                    <ReactQueryDevtoolsProduction />
                }  
            </React.Suspense>
        </QueryClientProvider>
    );
};

export default App;

import axios from 'axios';
import { IField } from '../../../@types/model/masterData/field/field';
import { IFieldUpsert } from '../../../@types/model/masterData/field/fieldUpsert';
import { UpsertTypeEnum } from '../../../@types/enum/upsertType';

export default class FieldHttpService {
    public static getList = async (lastSyncedTimeUnix : number, departmentIds : Array<number> | null, productionUnitIds : Array<number> | null, signal ?: AbortSignal) : Promise<Array<IField>> => {
        const result = await axios.get(`${API_URL}/v1/MasterData/Field/GetList`, {
            params: {
                lastSyncedTimeUnix,
                departmentIds,
                productionUnitIds,
            },
            paramsSerializer: {
                indexes: null
            },
            signal
        });

        return result.data;
    }

    public static upsert = async (upsert : IFieldUpsert, type : UpsertTypeEnum) : Promise<IField> => {
        const result = await axios.post(`${API_URL}/v1/MasterData/Field/${type}`, upsert);

        return result.data;
    }

    public static delete = async (id : number) : Promise<void> => {
        return await axios.delete(`${API_URL}/v1/MasterData/Field/Delete`, {
            params: { id },
        });
    }
}
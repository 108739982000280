import AuthActions from './actions';
import { createReducer } from '@reduxjs/toolkit';
import { IUserToken } from '../../@types/model/auth/userToken/userToken';

export interface IAuthState {
    isLoadingSession : boolean;
    isLoggedIn : boolean;
    session : null | IUserToken;
    token : string;
    isLoggingIn : boolean;
    isLoggingOut : boolean;
}

const initialState = {
    isLoadingSession: false,
    isLoggedIn: false,
    session: null,
    token: '',
    isLoggingIn: false,
    isLoggingOut: false,
};

const authReducer = createReducer<IAuthState>(initialState, (builder) => {
    builder.addCase(AuthActions.setIsLoadingSession, (state, action) => {
        state.isLoadingSession = action.payload;
    });
    builder.addCase(AuthActions.setSession, (state, action) => {
        state.session = action.payload;
    });
    builder.addCase(AuthActions.setLoggingIn, (state, action) => {
        state.isLoggingIn = action.payload;
    });
    builder.addCase(AuthActions.setLoggingOut, (state, action) => {
        state.isLoggingOut = action.payload;
    });
    builder.addCase(AuthActions.setToken, (state, action) => {
        state.token = action.payload;
    });
    builder.addCase(AuthActions.logout, () => {
        return initialState;
    });
});

export default authReducer;

import { createReducer } from '@reduxjs/toolkit';
import DataActions from './actions';

export interface IDataState {
    masterDataSyncIsLoading : boolean;

    selectedUserDivisionIds : Array<number> | null;
    selectedUserDepartmentIds : Array<number> | null;
}

const initialState = {
    masterDataSyncIsLoading: false,

    selectedUserDivisionIds: null,
    selectedUserDepartmentIds: null,
};

const dataReducer = createReducer<IDataState>(initialState, (builder) => {
    builder.addCase(DataActions.setIsMasterDataSyncRunning, (state, action) => {
        state.masterDataSyncIsLoading = action.payload;
    });
    builder.addCase(DataActions.setSelectedUserDivisionIds, (state, action) => {
        state.selectedUserDivisionIds = action.payload;
    });
    builder.addCase(DataActions.setSelectedUserDepartmentIds, (state, action) => {
        state.selectedUserDepartmentIds = action.payload;
    });
    builder.addCase(DataActions.reset, () => {
        return initialState;
    });
});

export default dataReducer;

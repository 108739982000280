import { createAction } from '@reduxjs/toolkit';
import { withPayloadType } from '@zz2/zz2-ui';

export default class DataActions {
    public static setIsMasterDataSyncRunning = createAction('DATA_SET_MASTER_DATA_SYNC_IS_LOADING', withPayloadType<boolean>());

    public static setSelectedUserDivisionIds = createAction('DATA_SET_USER_SELECTED_DIVISION_IDS', withPayloadType<Array<number> | null>());
    public static setSelectedUserDepartmentIds = createAction('DATA_SET_USER_SELECTED_DEPARTMENT_IDS', withPayloadType<Array<number> | null>());
    public static reset = createAction('DATA_RESET');
}

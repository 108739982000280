import DataActions from './actions';
import GeneralThunk from '../general/thunk';
import moment from 'moment';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkApi } from '../../@types/redux';
import { IMasterDataSync } from '../../@types/model/sync/masterDataSync';
import SyncHttpService from '../../service/http/masterData/syncHttpService';
import { setMasterDataLastSyncDateLocalStorage } from '../../service/localStorageService';
import { clearIndexedDBObjectStores, setIndexedDBMasterData } from '../../service/masterDataSyncService';

export default class DataThunks {
    /**
     * Sync all master data from the API,
     * Updating the indexedDB object stores once complete.
     *
     * @returns {IMasterDataSync | null}
     */
    public static manualSyncAllMasterData = createAsyncThunk<
        IMasterDataSync | null,
        undefined,
        ThunkApi>(
            'MASTER_DATA_MANUAL_SYNC_ALL',
            async (params, thunkApi) => {
                let result : IMasterDataSync | null = null;
                thunkApi.dispatch(DataActions.setIsMasterDataSyncRunning(true));
                thunkApi.dispatch(GeneralThunk.showInfoSnackbar('Master data full sync in progress.'));

                try {
                    const newMasterDataLastSyncDate : moment.Moment = moment().utc().subtract(1, 'hours');
                    
                    // Update last sync value to new value
                    setMasterDataLastSyncDateLocalStorage(newMasterDataLastSyncDate);

                    const res = await SyncHttpService.masterDataSyncAll(0);

                    result = res.data;
                } catch (e) {
                    /**
                     * Only show error if there is actually an error.
                     * Cancelation errors are empty.
                     */
                    if (e) {
                        thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while trying to load master data.', ex: e }));
                    }
                    return null;
                }

                try {
                    // clears all indexedDB object stores before updating
                    await clearIndexedDBObjectStores();

                    // Update indexedDB master data objects stores with new values
                    const updatedMasterData = await setIndexedDBMasterData(result);

                    thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Master data full sync completed.'));

                    return updatedMasterData;
                } catch (e) {
                    /**
                     * Only show error if there is actually an error.
                     * Cancelation errors are empty.
                     */
                    if (e) {
                        thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while trying update master data.', ex: e }));
                    }
                    return null;
                } finally {
                    thunkApi.dispatch(DataActions.setIsMasterDataSyncRunning(false));
                }
            },
        );
}
import axios from 'axios';
import { IRootStock } from '../../../@types/model/masterData/rootStock/rootStock';
import { IRootStockUpsert } from '../../../@types/model/masterData/rootStock/rootStockUpsert';
import { UpsertTypeEnum } from '../../../@types/enum/upsertType';

export default class RootStockHttpService {
    public static getList = async (lastSyncedTimeUnix : number, signal ?: AbortSignal) : Promise<Array<IRootStock>> => {
        const result = await axios.get(`${API_URL}/v1/MasterData/RootStock/GetList`, {
            params: {
                lastSyncedTimeUnix
            },
            signal
        });

        return result.data;
    }

    public static upsert = async (upsert : IRootStockUpsert, type : UpsertTypeEnum) : Promise<IRootStock> => {
        const result = await axios.post(`${API_URL}/v1/MasterData/RootStock/${type}`, upsert);

        return result.data;
    }

    public static delete = async (id : number) : Promise<void> => {
        return await axios.delete(`${API_URL}/v1/MasterData/RootStock/Delete`, {
            params: { id },
        });
    }
}
import axios from 'axios';
import { IChemicalCategory } from '../../../../@types/model/masterData/chemicals/chemicalCategory/chemicalCategory';
import { IChemicalCategoryUpsert } from '../../../../@types/model/masterData/chemicals/chemicalCategory/chemicalCategoryUpsert';
import { UpsertTypeEnum } from '../../../../@types/enum/upsertType';

export default class ChemicalCategoryHttpService {
    public static getList = async (lastSyncedTimeUnix : number, signal ?: AbortSignal) : Promise<Array<IChemicalCategory>> => {
        const result = await axios.get(`${API_URL}/v1/MasterData/ChemicalCategory/GetList`, {
            params: {
                lastSyncedTimeUnix
            },
            signal
        });

        return result.data;
    }

    public static upsert = async (upsert : IChemicalCategoryUpsert, type : UpsertTypeEnum) : Promise<IChemicalCategory> => {
        const result = await axios.post(`${API_URL}/v1/MasterData/ChemicalCategory/${type}`, upsert);

        return result.data;
    }

    public static delete = async (id : number) : Promise<void> => {
        return await axios.delete(`${API_URL}/v1/MasterData/ChemicalCategory/Delete`, {
            params: { id },
        });
    }
}
import axios from 'axios';
import { ISeedTraySize } from '../../../@types/model/masterData/seedTraySize/seedTraySize';
import { ISeedTraySizeUpsert } from '../../../@types/model/masterData/seedTraySize/seedTraySizeUpsert';
import { UpsertTypeEnum } from '../../../@types/enum/upsertType';

export default class SeedTraySizeHttpService {
    public static getList = async (lastSyncedTimeUnix : number, signal ?: AbortSignal) : Promise<Array<ISeedTraySize>> => {
        const result = await axios.get(`${API_URL}/v1/MasterData/SeedTraySize/GetList`, {
            params: {
                lastSyncedTimeUnix
            },
            signal
        });

        return result.data;
    }

    public static upsert = async (upsert : ISeedTraySizeUpsert, type : UpsertTypeEnum) : Promise<ISeedTraySize> => {
        const result = await axios.post(`${API_URL}/v1/MasterData/SeedTraySize/${type}`, upsert);

        return result.data;
    }

    public static delete = async (id : number) : Promise<void> => {
        return await axios.delete(`${API_URL}/v1/MasterData/SeedTraySize/Delete`, {
            params: { id },
        });
    }
}
import axios from 'axios';
import { ICommodity } from '../../../@types/model/masterData/commodity/commodity';
import { ICommodityUpsert } from '../../../@types/model/masterData/commodity/commodityUpsert';
import { UpsertTypeEnum } from '../../../@types/enum/upsertType';

export default class CommodityHttpService {
    public static getList = async (lastSyncedTimeUnix : number, signal ?: AbortSignal) : Promise<Array<ICommodity>> => {
        const result = await axios.get(`${API_URL}/v1/MasterData/Commodity/GetList`, {
            params: {
                lastSyncedTimeUnix
            },
            signal
        });

        return result.data;
    }


    public static upsert = async (upsert : ICommodityUpsert, type : UpsertTypeEnum) : Promise<ICommodity> => {
        const result = await axios.post(`${API_URL}/v1/MasterData/Commodity/${type}`, upsert);

        return result.data;
    }

    public static delete = async (id : number) : Promise<void> => {
        return await axios.delete(`${API_URL}/v1/MasterData/Commodity/Delete`, {
            params: { id },
        });
    }
}
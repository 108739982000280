import { createReducer } from '@reduxjs/toolkit';
import RightActions from './actions';

export interface IRightState {
    isLoading : boolean;
}

const initialState = {
    isLoading: false,
};

const rightReducer = createReducer<IRightState>(initialState, (builder) => {
    builder.addCase(RightActions.setIsLoading, (state, action) => {
        state.isLoading = action.payload;
    });
    builder.addCase(RightActions.reset, () => {
        return initialState;
    });
});

export default rightReducer;

import axios from 'axios';
import { IDepartment } from '../../../@types/model/masterData/department/department';
import { IDepartmentUpsert } from '../../../@types/model/masterData/department/departmentUpsert';
import { UpsertTypeEnum } from '../../../@types/enum/upsertType';

export default class DepartmentHttpService {
    public static getList = async (lastSyncedTimeUnix : number, departmentIds : Array<number> | null, subdivisionId : number | null, signal ?: AbortSignal) : Promise<Array<IDepartment>> => {
        if (!departmentIds || departmentIds.length < 1) return [];

        const result = await axios.get(`${API_URL}/v1/MasterData/Department/GetList`, {
            params: {
                lastSyncedTimeUnix,
                departmentIds,
                subdivisionId
            },
            paramsSerializer: {
                indexes: null
            },
            signal
        });

        return result.data;
    }

    public static upsert = async (upsert : IDepartmentUpsert, type : UpsertTypeEnum) : Promise<IDepartment> => {
        const result = await axios.post(`${API_URL}/v1/MasterData/Department/${type}`, upsert);

        return result.data;
    }

    public static delete = async (id : number) : Promise<void> => {
        return await axios.delete(`${API_URL}/v1/MasterData/Department/Delete`, {
            params: { id },
        });
    }
}
import axios from 'axios';
import { IRight } from '../../../@types/model/auth/right/right';
import { IRightUpsert } from '../../../@types/model/auth/right/rightUpsert';
import { UpsertTypeEnum } from '../../../@types/enum/upsertType';

export default class RightHttpService {
    public static getList = async (signal ?: AbortSignal) : Promise<Array<IRight>> => {
        const result = await axios.get(`${API_URL}/v1/Rights/Right/GetList`, {
            signal
        });
        return result.data;
    }

    public static upsert = async (upsert : IRightUpsert, type : UpsertTypeEnum) : Promise<IRight> => {
        const result = await axios.post(`${API_URL}/v1/Rights/Right/${type}`, upsert);

        return result.data;
    }

    public static delete = async (id : number) : Promise<void> => {
        return await axios.delete(`${API_URL}/v1/Rights/Right/Delete`, {
            params: { id },
        });
    }
}

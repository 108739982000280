import { createAppAsyncThunk } from '../../@types/redux';
import generalActions from './actions';

export default class GeneralThunk {
    /**
     * Adds an error snackbar to the queue. Attempts to retrieve the error message from the API, falling back to the default
     * message if the API returns no such thing.
     * @param defaultMessage
     * @param ex
     */
    public static showErrorSnackbar = createAppAsyncThunk(
        'general/showErrorSnackbar',
        async (params : {
            defaultMessage : string;
            ex ?: any;
        }, ThunkApi) => {
            let errorMessage = params.defaultMessage;

            if (params.ex == null)
            {
                errorMessage = 'Connection error.';
            } else if (typeof(params.ex) === 'string') {
                errorMessage = params.ex;
            } else if (typeof(params.ex.Message) === 'string') {
                errorMessage = params.ex.Message;
            } else if (typeof(params.ex.data?.Message) === 'string') {
                errorMessage = params.ex.data.Message;
            } else if (typeof(params.ex.response?.data?.Message) === 'string') {
                errorMessage = params.ex.response.data.Message;
            }

            ThunkApi.dispatch(generalActions.enqueueSnackbar({
                message: errorMessage,
                options: {
                    variant: 'error',
                },
            }));
        }
    )

    /**
     * Adds a success snackbar to the queue.
     * @param message
     */
    public static showSuccessSnackbar = createAppAsyncThunk(
        'general/showSuccessSnackbar',
        async (message : string, ThunkApi)=> {
            ThunkApi.dispatch(generalActions.enqueueSnackbar({
                message,
                options: {
                    variant: 'success',
                },
            }));
            
        }
    )

    /**
     * Adds a warning snackbar to the queue.
     * @param message
     */
    public static showWarningSnackbar = createAppAsyncThunk(
        'general/showWarningSnackbar',
        async (message : string, ThunkApi)=> {
            ThunkApi.dispatch(generalActions.enqueueSnackbar({
                message,
                options: {
                    variant: 'warning',
                },
            }));
            
        }
    )

    /**
     * Adds a info snackbar to the queue.
     * @param message
     */
        public static showInfoSnackbar = createAppAsyncThunk(
            'general/showInfoSnackBar',
            async (message : string, ThunkApi) => {    
                ThunkApi.dispatch(generalActions.enqueueSnackbar({
                    message,
                    options: {
                        variant: 'info',
                    },
                }));
                
            }
        )
}

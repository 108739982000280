import axios from 'axios';
import { IUser } from '../../../@types/model/auth/user/user';
import { IUserUpsert } from '../../../@types/model/auth/user/userUpsert';
import { IGoogleLogInView } from '../../../@types/model/auth/login/GoogleLogInView';
import { UpsertTypeEnum } from '../../../@types/enum/upsertType';

export default class UserHttpService {
    public static getList = async (signal ?: AbortSignal) : Promise<Array<IUser>> => {
        const result = await axios.get(`${API_URL}/v1/Rights/User/GetList`, {
            signal
        });
        return result.data;
    }

    public static upsert = async (upsert : Array<IUserUpsert>, type : UpsertTypeEnum) : Promise<Array<IUser>> => {
        const result = await axios.post(`${API_URL}/v1/Rights/User/${type}`, upsert);

        return result.data;
    }

    public static delete = async (id : number) : Promise<void> => {
        return await axios.delete(`${API_URL}/v1/Rights/User/Delete`, {
            params: { id },
        });
    }

    public static linkGoogleAccount = async (googleLogInView : IGoogleLogInView) : Promise<IUser | null> => {
        return await axios.post(`${API_URL}/v1/Rights/User/LinkGoogleAccountToUser`, googleLogInView);
    }

    public static unlinkGoogleAccount = async () : Promise<IUser | null> => {
        return await axios.post(`${API_URL}/v1/Rights/User/UnlinkGoogleAccountFromUser`);
    }
}

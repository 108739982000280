import { history } from '../index';

const push = history.push;
const goBack = history.goBack;
const replace = history.replace;

export const navPath = (pathname : string) : void => push(pathname);
export const navBack = () : void => goBack();
export const navReplace = (pathname : string) : void => replace(pathname);

const navActions = {
    navBack,
    navPath,
    navReplace
};

export default navActions;

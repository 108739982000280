import axios from 'axios';
import { ISystem } from '../../../@types/model/masterData/system/system';
import { ISystemUpsert } from '../../../@types/model/masterData/system/systemUpsert';
import { UpsertTypeEnum } from '../../../@types/enum/upsertType';

export default class SystemHttpService {
    public static getList = async (lastSyncedTimeUnix : number, signal ?: AbortSignal) : Promise<Array<ISystem>> => {
        const result = await axios.get(`${API_URL}/v1/MasterData/System/GetList`, {
            params: {
                lastSyncedTimeUnix
            },
            signal
        });

        return result.data;
    }

    public static upsert = async (upsert : ISystemUpsert, type : UpsertTypeEnum) : Promise<ISystem> => {
        const result = await axios.post(`${API_URL}/v1/MasterData/System/${type}`, upsert);

        return result.data;
    }

    public static delete = async (id : number) : Promise<void> => {
        return await axios.delete(`${API_URL}/v1/MasterData/System/Delete`, {
            params: { id },
        });
    }
}
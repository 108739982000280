import axios, { AxiosPromise } from 'axios';
import { IUserToken } from '../../@types/model/auth/userToken/userToken';
import { ISignUpView } from '../../@types/model/auth/login/SignUpView';
import { IGoogleLogInView } from '../../@types/model/auth/login/GoogleLogInView';
import { ILogInView } from '../../@types/model/auth/login/LogInView';

export default class AuthHttpService {

    public static signUp = (signUpView : ISignUpView) : AxiosPromise<IUserToken> => {
        return axios.post(`${API_URL}/v1/Authorisation/SignUp`, signUpView);
    };

    public static logInManual = (logInView : ILogInView) : AxiosPromise<IUserToken> => {
        return axios.post(`${API_URL}/v1/Authorisation/LogIn`, logInView);
    }

    public static googleLogIn = (googleLogInView : IGoogleLogInView) : AxiosPromise<IUserToken> => {
        return axios.post(`${API_URL}/v1/Authorisation/LogInGoogle`, googleLogInView);
    }

    public static getSession = () : AxiosPromise<IUserToken> => {
        return axios.get(`${API_URL}/v1/Authorisation/GetSession`);
    }

    public static logout = async () : Promise<void> => {
        return await axios.get(`${API_URL}/v1/Authorisation/LogOut`);
    }

    public static logoutUsers = async (userIds : Array<number>) : Promise<void> => {
        return await axios.post(`${API_URL}/v1/Authorisation/LogoutUsers`, userIds);
    }

    public static requestForgottenPasswordEmail = async(emailOrEmpNumber : string) : Promise<boolean> => {
        return await axios.post(`${API_URL}/v1/Rights/User/RequestForgottenPasswordEmail`, null, {
            params: {
                emailOrEmpNumber 
            }
        });
    }

    public static resetPassword = async (recoveryCode : string, email : string, newPassword : string) : Promise<boolean> => {
        return await axios.post(`${API_URL}/v1/Rights/User/ResetPassword`, {
            recoveryCode,
            newPassword,
            email,
        });
    }
}

import GeneralThunk from '../general/thunk';
import AuthActions from './actions';
import * as localStorageService from '../../service/localStorageService';
import AuthHttpService from '../../service/http/authHttpService';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkApi } from '../../@types/redux';
import { IUserToken } from '../../@types/model/auth/userToken/userToken';
import RightActions from '../right/actions';
import navActions from '../nav/actions';
import { ISignUpView } from '../../@types/model/auth/login/SignUpView';
import { IGoogleLogInView } from '../../@types/model/auth/login/GoogleLogInView';
import { ILogInView } from '../../@types/model/auth/login/LogInView';

export default class AuthThunk {
    /**
     * Performs log out request with API then sets the auth state accordingly.
     */
    public static logOut = createAsyncThunk<
        void,
        undefined,
        ThunkApi>(
            'AUTH_LOG_OUT',
            async (params, thunkApi) => {
                try {
                    thunkApi.dispatch(AuthActions.setLoggingOut(true));

                    await AuthHttpService.logout();

                    thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Success'));

                    thunkApi.dispatch(AuthActions.logout());
                } catch (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while logging out.', ex: e }));
                } finally {
                    await localStorageService.setLocalStorageSession(null);
                    thunkApi.dispatch(AuthActions.setLoggingOut(false));
                }
            },
        );

    /**
     * Performs log out request with API for specified users then sets the auth state accordingly.
     * 
     * @param {Array<number>} userIds
     * @returns {boolean}
     */
    public static logoutUsers = createAsyncThunk<
    boolean,
    Array<number>,
    ThunkApi>(
        'AUTH_LOG_OUT',
        async (userIds, thunkApi) => {
            try {
                thunkApi.dispatch(RightActions.setIsLoading(true)); // setting rightActions loading indicator since this functions only gets used in user manager

                const state = thunkApi.getState();
                const currentUser = state.auth.session?.user;
                const includesCurrentUser = !!userIds.find(id => id == currentUser?.id);

                await AuthHttpService.logoutUsers(userIds);

                if (includesCurrentUser) {
                    thunkApi.dispatch(AuthActions.logout());
                    await localStorageService.setLocalStorageSession(null);
                    navActions.navReplace('/login');
                    return true;
                }
                
                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar(`${userIds.length === 1 ? 'User' : 'Users'} logged out successfully`));
                return true;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while logging out users.', ex: e }));
                return false;
            } finally {
                thunkApi.dispatch(RightActions.setIsLoading(false));
            }
        },
    );

    /**
     * Performs sign up request with API then sets the interceptor (using auth token) and auth state accordingly. Kicks off
     * the setup for local storage service to store the session and, in turn, firing its callback (that is set during app
     * init).
     *
     * @param employeeNumber
     * @param password
     */
    public static signUp = createAsyncThunk<
    IUserToken | null,
    {
        employeeNumber : string;
        password : string;
    },
    ThunkApi>(
        'AUTH_SIGN_UP',
        async (params, thunkApi) => {
            try {
                thunkApi.dispatch(AuthActions.setLoggingIn(true));

                const signUpView : ISignUpView = {
                    employeeNumber: params.employeeNumber,
                    password: params.password
                };

                const res = await AuthHttpService.signUp(signUpView);
                await localStorageService.setLocalStorageSession(res.data);

                return res.data;
            } catch (e) {
                thunkApi.dispatch(AuthActions.setLoggingIn(false));
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while signing up.', ex: e }));
                return null;
            } finally {
                thunkApi.dispatch(AuthActions.setLoggingIn(false));
            }
        },
    );

    /**
     * Performs log in request with API then sets the interceptor (using auth token) and auth state accordingly. Kicks off
     * the setup for local storage service to store the session and, in turn, firing its callback (that is set during app
     * init).
     *
     * @param idToken
     */
    public static googleLogIn = createAsyncThunk<
    IUserToken | null,
    {
        code : string;
    },
    ThunkApi>(
        'AUTH_GOOGLE_LOG_IN',
        async (params, thunkApi) => {
            try {
                thunkApi.dispatch(AuthActions.setLoggingIn(true));

                const googleLogInView : IGoogleLogInView = {
                    code: params.code
                };

                const res = await AuthHttpService.googleLogIn(googleLogInView);
                await localStorageService.setLocalStorageSession(res.data);

                return res.data;
            } catch (e) {
                thunkApi.dispatch(AuthActions.setLoggingIn(false));
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while logging in.', ex: e }));
                return null;
            } finally {
                thunkApi.dispatch(AuthActions.setLoggingIn(false));
            }
        },
    );

    /**
     * Performs log in request with API then sets the interceptor (using auth token) and auth state accordingly. Kicks off
     * the setup for local storage service to store the session and, in turn, firing its callback (that is set during app
     * init).
     *
     * @param username
     * @param password
     */
    public static manualLogIn = createAsyncThunk<
    IUserToken | null,
    {
        username : string;
        password : string;
    },
    ThunkApi>(
        'AUTH_MANUAL_LOGIN',
        async (params, thunkApi) => {
            try {
                thunkApi.dispatch(AuthActions.setLoggingIn(true));

                const loginView : ILogInView = {
                    emailOrUsername: params.username,
                    password: params.password
                };

                const res = await AuthHttpService.logInManual(loginView);

                await localStorageService.setLocalStorageSession(res.data);
                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Success'));

                return res.data;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while logging in.', ex: e }));
                return null;
            } finally {
                thunkApi.dispatch(AuthActions.setLoggingIn(false));
            }
        },
    );

    /**
     * Retrieves current session for user
     *
     */
    public static getSession = createAsyncThunk<
    IUserToken | null,
    undefined,
    ThunkApi>(
        'AUTH_GET_SESSION',
        async (params, thunkApi) => {
            try {
                thunkApi.dispatch(AuthActions.setIsLoadingSession(true));

                const res = await AuthHttpService.getSession();

                await localStorageService.setLocalStorageSession(res.data);

                return res.data;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading session.', ex: e }));
                return null;
            } finally {
                thunkApi.dispatch(AuthActions.setIsLoadingSession(false));
            }
        },
    );

    /**
     * Performs request for password reset email
     *
     * @param emailOrUsername
     */
    public static requestForgottenPassword = createAsyncThunk<
    boolean | null,
    {
        emailOrUsername : string;
    },
    ThunkApi>(
        'REQUEST_FORGOTTEN_PASSWORD',
        async (params, thunkApi) => {
            thunkApi.dispatch(RightActions.setIsLoading(true));
            try {
                const res = await AuthHttpService.requestForgottenPasswordEmail(params.emailOrUsername);
                if (res) {
                    thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Password recovery submitted, please check your email'));
                    return res;
                } else {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({defaultMessage: 'Password recovery could not be submitted'}));
                    return null;
                }
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while recovering password.', ex: e }));
                return null;
            } finally {
                thunkApi.dispatch(AuthActions.setLoggingIn(false));
                thunkApi.dispatch(RightActions.setIsLoading(false));
            }
        },
    );

    /**
     * Performs request for password reset using recovery code received in email
     *
     * @param emailOrUsername
     */
    public static passwordReset = createAsyncThunk<
    boolean | null,
    {
        recoveryCode : string;
        password : string;
        email : string;
    },
    ThunkApi>(
        'RESET_PASSWORD',
        async (params, thunkApi) => {
            thunkApi.dispatch(RightActions.setIsLoading(true));
            try {
                const res = await AuthHttpService.resetPassword(params.recoveryCode, params.email, params.password);
                if (res) {
                    thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Password Change Successful'));
                    return res;
                } else {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({defaultMessage: 'Password recovery could not be reset'}));
                    return null;
                }
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while subimtting password reset request.', ex: e }));
                return null;
            } finally {
                thunkApi.dispatch(AuthActions.setLoggingIn(false));
                thunkApi.dispatch(RightActions.setIsLoading(false));
            }
        },
    );
}


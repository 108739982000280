import React, { useEffect, useMemo, useState } from 'react';
import { MuiAutocompleteSelect, ProjectDialog, PillButton, GoogleSignInButton, IGoogleLoginSuccess, IGoogleLoginError, CustomTooltip, ConfirmationDialog } from '@zz2/zz2-ui';
import { useAppDispatch } from '../../@types/redux';
import { IUser } from '../../@types/model/auth/user/user';
import { getMasterDataLastSyncDateLocalStorage, getUserSelectedDepartmentsLocalStorage, getUserSelectedDivisionsLocalStorage, setUserSelectedDepartmentsLocalStorage, setUserSelectedDivisionsLocalStorage } from '../../service/localStorageService';
import DataActions from '../../store/data/actions';
import moment from 'moment';
import { Button, IconButton, Tooltip, Typography } from '@mui/material';
import GeneralThunk from '../../store/general/thunk';
import DataThunks from '../../store/data/thunk';
import { OptionType } from '../../@types/model/optionType';
import { IOptionType } from '@zz2/zz2-ui';
import { useGetDivisions } from '../../hooks/query/masterData/divisionQueries';
import { useGetDepartments } from '../../hooks/query/masterData/departmentQueries';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useHandleLinkGoogleAccount, useHandleUnlinkGoogleAccount } from '../../hooks/query/rights/userQueries';
import { ClearIcon } from '@mui/x-date-pickers';

interface IUserSettingsProps {
    isOpen : boolean;
    setIsUserDialogOpenCallback : (state : boolean) => void;
    currentUser : IUser | null;
    onClose : () => void;
    signOut : () => void;
}

const UserSettings = (props : IUserSettingsProps) : React.ReactElement => {
    const dispatch = useAppDispatch();

    const selectedDivisions = getUserSelectedDivisionsLocalStorage();
    const selectedDepartments = getUserSelectedDepartmentsLocalStorage();

    const [selectedDivisionOptions, setSelectedDivisionOptions] = useState<Array<IOptionType>>([]);
    const [selectedDepartmentOptions, setSelectedDepartmentOptions] = useState<Array<IOptionType>>([]);
    const [isUnlinkGoogleAccountDialogOpen, setIsUnlinkGoogleAccountDialogOpen] = useState<boolean>(false);

    /*================================================================================================================
     *                                                  Queries
     * ==============================================================================================================*/

    const { isLoading: isLoadingDivisions, data: divisions } = useGetDivisions({ divisionIds: null });
    const { isLoading: isLoadingDepartments, data: departments } = useGetDepartments({ departmentIds: null, subdivisionId: null});
    const { isLoading: isUnlinkingGoogleAccount, mutate: unlinkGoogleAccount } = useHandleUnlinkGoogleAccount();
    const { isLoading: isLinkingGoogleAccount, mutate: linkGoogleAccount } = useHandleLinkGoogleAccount();

    /*================================================================================================================
     *                                                  Effects
     * ==============================================================================================================*/

    useEffect(() => {
        if (props.currentUser) {
            loadData();
        }
    }, [props.isOpen, divisions, departments]);


    /*================================================================================================================
     *                                                  Async Methods
     * ==============================================================================================================*/

    
    const loadData = () : void => {
        if (props.currentUser?.divisionIds && props.currentUser.divisionIds.length > 0) {
            const selectedDivisionOptions = divisions?.filter(div => selectedDivisions.some(localSelectedDiv => div.id === localSelectedDiv.value))
                .map(OptionType.fromDataModel) ?? [];

            if (selectedDivisions.length < 1) return;

            setSelectedDivisionOptions(selectedDivisionOptions);
            dispatch(DataActions.setSelectedUserDivisionIds(selectedDivisions.map(x => Number(x.value))));
        } else {
            setUserSelectedDivisionsLocalStorage([]);
            dispatch(DataActions.setSelectedUserDivisionIds(null));
        }

        if (props.currentUser?.departmentIds && props.currentUser.departmentIds.length > 0) {
            const selectedDepartmentOptions = departments?.filter(dept => selectedDepartments.some(localSelectedDept => dept.id === localSelectedDept.value))
                .map(OptionType.fromDataModel) ?? [];

            if (selectedDepartments.length < 1) return;

            setSelectedDepartmentOptions(selectedDepartmentOptions);
            dispatch(DataActions.setSelectedUserDepartmentIds(selectedDepartments.map(x => Number(x.value))));
        } else {
            setUserSelectedDepartmentsLocalStorage([]);
            dispatch(DataActions.setSelectedUserDepartmentIds(null));
        }
    };

    const onGoogleAccountLinkSuccess = async (response : IGoogleLoginSuccess) : Promise<void> => {
        linkGoogleAccount({ code: response.code });
    };

    const onGoogleAccountLinkFailure = (response : IGoogleLoginError) : void => {
        dispatch(GeneralThunk.showErrorSnackbar({
            defaultMessage: response.error ?? 'Google Account linking failed.',
            ex: response,
        }));
    };

    const onUnlinkGoogleAccount = () : void => {
        if (!props.currentUser)
            return;

        unlinkGoogleAccount();
        closeUnlinkGoogleAccountDialog();
    };


    /*================================================================================================================
     *                                                  Handler Methods
     * ==============================================================================================================*/


    const onDivisionChange = (selectedOptions : Array<IOptionType>) : void => {
        setSelectedDivisionOptions(selectedOptions);
        setSelectedDepartmentOptions([]);
    };

    const onDepartmentChange = (selectedOptions : Array<IOptionType>) : void => {
        setSelectedDepartmentOptions(selectedOptions);
    };

    const onDivisionSelectAll = () : void => {
        if (divisionOptions.length !== selectedDivisionOptions.length) {
            onDivisionChange(divisionOptions);
        } else {
            onDivisionChange([]);
            onDepartmentChange([]);
        }
    };

    const onDepartmentSelectAll = () : void => {
        if (departmentOptions.length !== selectedDepartmentOptions.length) {
            onDepartmentChange(departmentOptions);
        } else {
            onDepartmentChange([]);
        }
    };

    const onMasterDataResyncClick = async () : Promise<void> => {
        if (!props.isOpen) return;
        props.setIsUserDialogOpenCallback(false);
        dispatch(DataThunks.manualSyncAllMasterData());
    };

    const onCloseUserSettings = () : void => {
        if (selectedDivisionOptions.length < 1 || selectedDepartmentOptions.length < 1) {
            dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'Select at least one division and department.' }));
            return;
        }

        setUserSelectedDivisionsLocalStorage(selectedDivisionOptions);
        dispatch(DataActions.setSelectedUserDivisionIds(selectedDivisionOptions.map(x => Number(x.value))));
        setUserSelectedDepartmentsLocalStorage(selectedDepartmentOptions);
        dispatch(DataActions.setSelectedUserDepartmentIds(selectedDepartmentOptions.map(x => Number(x.value))));
        props.onClose();
    };

    const getMasterDataLastSyncDate = () : string => {
        const lastSyncDate : moment.Moment | null = getMasterDataLastSyncDateLocalStorage();

        return lastSyncDate ? moment(lastSyncDate).format('DD/MM/YYYY HH:mm:ss') : '';
    };

    const openUnlinkEmailDialog = () : void => setIsUnlinkGoogleAccountDialogOpen(true);

    const closeUnlinkGoogleAccountDialog = () : void => setIsUnlinkGoogleAccountDialogOpen(false);


    /*================================================================================================================
     *                                                  Memos
     * ==============================================================================================================*/

    const isLoading = useMemo<boolean>(() => {
        return isLinkingGoogleAccount
        || isUnlinkingGoogleAccount
        || isLoadingDivisions
        || isLoadingDepartments;
    }, [
        isLinkingGoogleAccount,
        isUnlinkingGoogleAccount,
        isLoadingDivisions,
        isLoadingDepartments
    ]);


    const divisionOptions = useMemo<Array<IOptionType>>(() => {
        if (!divisions || !props.currentUser || !props.currentUser.divisionIds) return [];

        return divisions
            .filter(div => div.isActive && (props.currentUser?.divisionIds?.some(divId => div.id === divId)))
            .map(OptionType.fromDataModel);
    }, [divisions, props.currentUser]);

    const departmentOptions = useMemo<Array<IOptionType>>(() => {
        if (!departments || !props.currentUser || !props.currentUser.departmentIds) return [];

        const selectedDivisionIds = selectedDivisionOptions.map(x => x.value);

        return departments
            .filter(dept => dept.isActive
                && props.currentUser?.departmentIds?.includes(dept.id)
                && selectedDivisionIds.includes(dept.subdivision?.divisionId ?? 0))
            .map(OptionType.fromDataModel);
    }, [departments, props.currentUser, selectedDivisionOptions]);


    /*================================================================================================================
     *                                                  Render Methods
     * ==============================================================================================================*/

    return (
        <ProjectDialog
            title={'User Settings'}
            isOpen={props.isOpen}
            fullWidth
            maxWidth={'xs'}
            isLoadingCircular={isLoading}
            onClose={onCloseUserSettings}>
            <div className={'flx1 fdc aic jcc m10'}>
                <MuiAutocompleteSelect
                    isMulti
                    className={'w300 mt30'}
                    name={'divisions'}
                    label={'Divisions'}
                    options={divisionOptions}
                    value={selectedDivisionOptions}
                    onChange={onDivisionChange}
                />
                <Button size={'small'} onClick={onDivisionSelectAll} disabled={divisionOptions.length < 1}>
                    {(divisionOptions.length > 0 && divisionOptions.length === selectedDivisionOptions.length) ? 'Unselect All' : 'Select All'}
                </Button>
                <MuiAutocompleteSelect
                    isMulti
                    className={'w300 mt30 mxh200 oya'}
                    name={'departments'}
                    label={'Departments'}
                    options={departmentOptions}
                    value={selectedDepartmentOptions}
                    onChange={onDepartmentChange}
                />
                <Button size={'small'} onClick={onDepartmentSelectAll} disabled={departmentOptions.length < 1}>
                    {(departmentOptions.length > 0 && departmentOptions.length === selectedDepartmentOptions.length) ? 'Unselect All' : 'Select All'}
                </Button>
                <div className={'fdc mt10 mb10 aic jcc'}>
                    <Typography variant={'body2'}>{`Last sync date: ${getMasterDataLastSyncDate()}`}</Typography>
                    <Tooltip className='aic jcc' title={'Does a full resyncing of the master data'}>
                        <Button variant={'text'} onClick={onMasterDataResyncClick}>Resync Master Data</Button>
                    </Tooltip>
                </div>
                <div className={'fdc aic'}>
                    {
                        (!props.currentUser?.email || !props.currentUser.profilePictureURL)
                            && <div className={'zi1'}> 
                                <GoogleOAuthProvider clientId={OAUTH_CLIENT_ID}>
                                    <GoogleSignInButton          
                                        onGoogleSignInSuccess={onGoogleAccountLinkSuccess}
                                        onGoogleSignInFailure={onGoogleAccountLinkFailure}   
                                        buttonText={`Link ${(!!props.currentUser?.email && !props.currentUser.profilePictureURL) ? 'Profile Picture' : 'Google Account'}`}   
                                    />
                                </GoogleOAuthProvider>
                            </div>
                    }
                    {
                        !!props.currentUser?.email
                        && <div className={'fdc aic jcc'}>
                            <div className={'fdr aic jcc'}>
                                <Typography className={'fdr m10'}>Linked Email:</Typography>
                                <Typography variant={'body1'} className={'fdr fwb cpd m10'}>{ props.currentUser.email }</Typography>
                                <CustomTooltip title={'Unlink Email'}>
                                    <IconButton className={'cr'} onClick={openUnlinkEmailDialog}>
                                        <ClearIcon />
                                    </IconButton>
                                </CustomTooltip>
                            </div>
                        </div>
                    }
                </div>
                <div className={'fdr aic pb10'}>
                    <PillButton
                        className={'h35 w150'}
                        text={'LOGOUT'}
                        color={'secondary'}
                        onClick={props.signOut}
                    />
                </div>
            </div>
            <ConfirmationDialog 
                open={isUnlinkGoogleAccountDialogOpen}
                title={'Unlink Email'}
                description={'Are you sure you would like to unlink the email address currently associated with your account?'}
                onAccept={onUnlinkGoogleAccount}
                onClose={closeUnlinkGoogleAccountDialog}
                dialogType={'orange'}
            />
        </ProjectDialog >
    );
};

export default UserSettings;
import { OptionsObject } from 'notistack';
import { IAuthState } from '../store/auth/reducer';
import { IGeneralState } from '../store/general/reducer';
import { IDataState } from '../store/data/reducer';
import { IRightState } from '../store/right/reducer';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AsyncThunk, AsyncThunkPayloadCreator, createAsyncThunk, Draft } from '@reduxjs/toolkit';
import store, { createRootReducer } from '../store';
import { ISyncState } from '../store/syncing/reducer';

export type DispatchCall<P> = (payload : P) => void;
export type DispatchCallEmpty = () => void;

export interface INotification {
    message : string | React.ReactNode;
    options ?: Draft<OptionsObject>;
}

export interface ISnackbarNotification extends INotification {
    key : number;
}

export type RootState = ReturnType<typeof createRootReducer>;

export interface IRootState {
    auth : IAuthState;
    general : IGeneralState;
    data : IDataState;
    right : IRightState;
    sync : ISyncState;
}


export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector : TypedUseSelectorHook<RootState> = useSelector;

export type ThunkApi = {
    dispatch : AppDispatch;
    state : RootState;
};

interface ThunkConfig {
    state : RootState;
}

export type payloadCreator<Returned, ThunkArg> = AsyncThunkPayloadCreator<Returned, ThunkArg, ThunkConfig>;
export declare function AppAsyncThunk<Returned = void, ThunkArg = void>(typePrefix : string, payloadCreator : payloadCreator<Returned, ThunkArg>) : AsyncThunk<Returned, ThunkArg, ThunkConfig>;
export const createAppAsyncThunk : typeof AppAsyncThunk = (typePrefix : string, payloadCreator) => createAsyncThunk(typePrefix, payloadCreator);

import { MutationKey, useQueryClient } from '@tanstack/react-query';
import { useAppDispatch, useAppSelector } from '../../@types/redux';
import GeneralThunk from '../../store/general/thunk';
import { ArrayHelper, IBaseModel } from '@zz2/zz2-ui';
import { IUser } from '../../@types/model/auth/user/user';
import { IUserToken } from '../../@types/model/auth/userToken/userToken';
import { setLocalStorageSession } from '../../service/localStorageService';
import AuthActions from '../../store/auth/actions';
import { getData, setData } from '../../service/masterDataSyncService';

export const queryErrorHandler = (errorMessage : string) : (error : Error) => void => {
    const dispatch = useAppDispatch();

    const handleError = (error : Error) : void => {
        dispatch(GeneralThunk.showErrorSnackbar({
            defaultMessage: errorMessage,
            ex: error,
        }));
    };
    
    return handleError;
};

export const querySuccessHandler = <T extends { id : number }>(getKey : MutationKey, successMessage : string, getKeyFilterId ?: string) : (result : T | Array<T>) => void => {
    const dispatch = useAppDispatch();
    const queryClient = useQueryClient();

    const handleOnSuccess = (result : T | Array<T>) : void => {
        const isArrayType = Array.isArray(result);

        let queryKey = getKey;

        if (getKeyFilterId) {
            const dataModel = isArrayType ? result[0] : result;

            if (getKeyFilterId in dataModel) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                queryKey = [getKey, (dataModel as any)[getKeyFilterId]];
            }
        }
        
        queryClient.setQueryData(queryKey, (cachedData ?: Array<T>) => {
            if (!cachedData) return [result];

            if (isArrayType) {
                return ArrayHelper.upsertElements(cachedData, [...result], (a : T, b : T) => a.id == b.id);
            } else {
                return ArrayHelper.upsertElement(cachedData, result, (x => x.id === result.id));
            }
        });

        dispatch(GeneralThunk.showSuccessSnackbar(successMessage));
    };

    return handleOnSuccess;
};

export const queryMasterDataSuccessHandler = <T extends IBaseModel>(getKey : MutationKey, successMessage : string, tableName : string, getKeyFilterId ?: string) : (result : T | Array<T>) => void => {
    const dispatch = useAppDispatch();
    const queryClient = useQueryClient();

    const handleOnSuccess = async (result : T | Array<T>) : Promise<void> => {
        const isArrayType = Array.isArray(result);

        let queryKey = getKey;

        if (getKeyFilterId) {
            const dataModel = isArrayType ? result[0] : result;

            if (getKeyFilterId in dataModel) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                queryKey = [getKey, (dataModel as any)[getKeyFilterId]];
            }
        }
        
        // Update query cache
        queryClient.setQueryData(queryKey, (cachedData ?: Array<T>) => {
            if (!cachedData) return [result];

            if (isArrayType) {
                return ArrayHelper.upsertElements(cachedData, [...result], (a : T, b : T) => a.id == b.id);
            } else {
                return ArrayHelper.upsertElement(cachedData, result, (x => x.id === result.id));
            }
        });

        // Attempt to update indexedDB
        try {
            const data = await getData<T>(tableName);

            let updatedList = data;

            if (isArrayType) {
                updatedList = ArrayHelper.upsertElements(data, [...result], (a : T, b : T) => a.id == b.id) ?? [];
            } else {
                updatedList = ArrayHelper.upsertElement(data, result, (x => x.id === result.id)) ?? [];
            }

            await setData<T>(tableName, updatedList);

            dispatch(GeneralThunk.showSuccessSnackbar(successMessage));
        } catch {
            dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: `Failed to update ${tableName} in indexedDB. Please refresh the screen.` }));
        }
    };

    return handleOnSuccess;
};

export const queryDeleteSuccessHandler = <T extends { id : number; isActive : boolean }>(getKey : MutationKey, successMessage : string) : (data : void, deletedId : number) => void => {
    const dispatch = useAppDispatch();
    const queryClient = useQueryClient();

    const handleOnSuccess = (data : void, deletedId : number) : void => {
        queryClient.setQueryData(getKey, (cachedData ?: Array<T>) => {
            if (!cachedData) return [cachedData];

            const deletedData = cachedData.find(x => x.id === deletedId);

            if (deletedData) {
                deletedData.isActive = false;
                return ArrayHelper.upsertElement(cachedData, deletedData, (x => x.id === deletedId));
            }
            
            return;
        });

        dispatch(GeneralThunk.showSuccessSnackbar(successMessage));
    };

    return handleOnSuccess;
};

export const queryDeleteMasterDataSuccessHandler = <T extends IBaseModel>(getKey : MutationKey, successMessage : string, tableName : string) : (data : void, deletedId : number) => void => {
    const dispatch = useAppDispatch();
    const queryClient = useQueryClient();

    const handleOnSuccess = async (data : void, deletedId : number) : Promise<void> => {
        // Update query cache
        queryClient.setQueryData(getKey, (cachedData ?: Array<T>) => {
            if (!cachedData) return [cachedData];

            const deletedData = cachedData.find(x => x.id === deletedId);

            if (deletedData) {
                deletedData.isActive = false;
                return ArrayHelper.upsertElement(cachedData, deletedData, (x => x.id === deletedId));
            }
            
            return;
        });

        // Attempt to update indexedDB
        try {
            const data = await getData<T>(tableName);

            const deletedData = data.find(x => x.id === deletedId);

            if (deletedData) {
                deletedData.isActive = false;
                const updatedList = ArrayHelper.upsertElement(data, deletedData, (x => x.id === deletedData.id)) ?? data;

                await setData<T>(tableName, updatedList);
            }

            dispatch(GeneralThunk.showSuccessSnackbar(successMessage));
        } catch {
            dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: `Failed to update ${tableName} in indexedDB. Please refresh the screen.` }));
        }
    };

    return handleOnSuccess;
};

export const queryNoReturnContentSuccessHandler = (successMessage : string) : () => void => {
    const dispatch = useAppDispatch();
    
    const handleOnSuccess = () : void => {
        dispatch(GeneralThunk.showSuccessSnackbar(successMessage));
    };

    return handleOnSuccess;
};

export const queryUpdateSessionSuccessHandler = (successMessage : string) : (data : IUser) => Promise<void> => {
    const dispatch = useAppDispatch();
    const session = useAppSelector<IUserToken | null>(x => x.auth.session);

    const handleOnSuccess = async (data : IUser) : Promise<void> => {
        if (!session) return;

        const updatedSession = {
            ...session,
            user: data,
        };

        dispatch(AuthActions.setSession(updatedSession));
        await setLocalStorageSession(updatedSession);
        
        dispatch(GeneralThunk.showSuccessSnackbar(successMessage));
    };

    return handleOnSuccess;
};
import { useQuery, useMutation, UseQueryResult } from '@tanstack/react-query';
import { queryErrorHandler, querySuccessHandler, queryDeleteSuccessHandler } from '../queryStateHandlers';
import { DELETE_RIGHT_KEY, GET_RIGHTS_KEY, UPSERT_RIGHT_KEY } from '../queryConstants';
import RightHttpService from '../../../service/http/right/rightHttpService';
import { IRight } from '../../../@types/model/auth/right/right';
import { IRightUpsert } from '../../../@types/model/auth/right/rightUpsert';
import { UpsertTypeEnum } from '../../../@types/enum/upsertType';

export const useGetRights = (enabled ?: boolean) : UseQueryResult<Array<IRight>, Error> => {
    const query = useQuery<Array<IRight>, Error>({
        queryKey: [GET_RIGHTS_KEY],
        queryFn: ({ signal }) => RightHttpService.getList(signal),
        onError: queryErrorHandler('Error loading rights.'),
        enabled: enabled ?? true,
    });

    return query;
};

export const useHandleRightUpsert = () => {
    const result = useMutation<IRight, Error, { upsert : IRightUpsert; type : UpsertTypeEnum }>({
        mutationKey: [UPSERT_RIGHT_KEY],
        mutationFn: (args : { upsert : IRightUpsert; type : UpsertTypeEnum }) => RightHttpService.upsert(args.upsert, args.type),
        onError: queryErrorHandler('Error creating/updating right.'),
        onSuccess: querySuccessHandler([GET_RIGHTS_KEY], 'Right updated successfully.'),
    });

    return result;
};

export const useHandleRightDelete = () => {
    const result = useMutation<void, Error, number>({
        mutationKey: [DELETE_RIGHT_KEY],
        mutationFn: (id : number) => RightHttpService.delete(id),
        onError: queryErrorHandler('Error deleting right.'),
        onSuccess: queryDeleteSuccessHandler([GET_RIGHTS_KEY], 'Right inactivated successfully.'),
    });

    return result;
};
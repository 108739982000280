import React from 'react';
import { useAppDispatch } from '../../@types/redux';
import { Screen } from '@zz2/zz2-ui';
import FreshServiceActions from '../../store/freshService/actions';

const NoRightsScreen = () : JSX.Element => {
    const dispatch = useAppDispatch();

    /*================================================================================================================
     *                                                  Effects
     * ==============================================================================================================*/

    /*================================================================================================================
     *                                                  Async Methods
     * ==============================================================================================================*/

    /*================================================================================================================
     *                                                  Handler Methods
     * ==============================================================================================================*/

    const requestAccess = () : void => {
        dispatch(FreshServiceActions.setIsSupportTicketDialogOpen(true));
    };

    /*================================================================================================================
     *                                                  Memos
     * ==============================================================================================================*/

    return (
        <Screen useAnimatedLoader>
            <div className={'fdc hfill jcc aic'}>
                <img src={`${ASSET_BASE}/assets/images/no_rights.svg`}></img>
                <p className={'cgray3 fs37'}>Sorry!</p>
                <p className={'cgray3 fs25'}>You do not have access to this page.</p>
                <a onClick={requestAccess} className='cursorPointer ul cg3'>Click here to open a ticket.</a>
            </div>
        </Screen>
    );
};

export default NoRightsScreen;

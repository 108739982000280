import { useMutation, useQuery, UseQueryResult } from '@tanstack/react-query';
import { queryDeleteMasterDataSuccessHandler, queryErrorHandler, queryMasterDataSuccessHandler } from '../queryStateHandlers';
import { DELETE_DIVISION_KEY, GET_DIVISIONS_KEY, UPSERT_DIVISION_KEY } from '../queryConstants';
import { IDivision } from '../../../@types/model/masterData/division/division';
import QueryMiddleware from '../../../middleware/queryMiddleware';
import { DIVISION_TABLE_NAME } from '../../../service/masterDataSyncService';
import { useAppDispatch } from '../../../@types/redux';
import { IDivisionUpsert } from '../../../@types/model/masterData/division/divisionUpsert';
import DivisionHttpService from '../../../service/http/masterData/divisionHttpService';
import { IDivisionQueryParams } from '../../../@types/model/queryParams/masterDataQueryParams';
import { UpsertTypeEnum } from '../../../@types/enum/upsertType';

export const useGetDivisions = (params : IDivisionQueryParams, enabled ?: boolean) : UseQueryResult<Array<IDivision>, Error> => {
    const dispatch = useAppDispatch();
    const query = useQuery<Array<IDivision>, Error>({
        queryKey: [GET_DIVISIONS_KEY],
        queryFn: ({ signal }) => QueryMiddleware.getSyncList<IDivision>(DIVISION_TABLE_NAME, dispatch, { divisionParams: params }, signal),
        onError: queryErrorHandler('Error loading divisions.'),
        enabled: enabled ?? true,
    });

    return query;
};

export const useHandleDivisionUpsert = () => {
    const result = useMutation<IDivision, Error, { upsert : IDivisionUpsert; type : UpsertTypeEnum }>({
        mutationKey: [UPSERT_DIVISION_KEY],
        mutationFn: (args : { upsert : IDivisionUpsert; type : UpsertTypeEnum }) => DivisionHttpService.upsert(args.upsert, args.type),
        onError: queryErrorHandler('Error creating/updating Division.'),
        onSuccess: queryMasterDataSuccessHandler([GET_DIVISIONS_KEY], 'Division updated successfully.', DIVISION_TABLE_NAME),
    });

    return result;
};

export const useHandleDivisionDelete = () => {
    const result = useMutation<void, Error, number>({
        mutationKey: [DELETE_DIVISION_KEY],
        mutationFn: (id : number) => DivisionHttpService.delete(id),
        onError: queryErrorHandler('Error deleting Division.'),
        onSuccess: queryDeleteMasterDataSuccessHandler([GET_DIVISIONS_KEY], 'Division inactivated successfully.', DIVISION_TABLE_NAME),
    });

    return result;
};
import axios from 'axios';
import { ISafetyPrecautionPpe } from '../../../../@types/model/masterData/chemicals/safetyPrecautionPpe/safetyPrecautionPpe';
import { ISafetyPrecautionPpeUpsert } from '../../../../@types/model/masterData/chemicals/safetyPrecautionPpe/safetyPrecautionPpeUpsert';
import { UpsertTypeEnum } from '../../../../@types/enum/upsertType';

export default class SafetyPrecautionPpeHttpService {
    public static getList = async (lastSyncedTimeUnix : number, signal ?: AbortSignal) : Promise<Array<ISafetyPrecautionPpe>> => {
        const result = await axios.get(`${API_URL}/v1/MasterData/SafetyPrecautionPpe/GetList`, {
            params: {
                lastSyncedTimeUnix
            },
            signal
        });

        return result.data;
    }

    public static upsert = async (upsert : ISafetyPrecautionPpeUpsert, type : UpsertTypeEnum) : Promise<ISafetyPrecautionPpe> => {
        const result = await axios.post(`${API_URL}/v1/MasterData/SafetyPrecautionPpe/${type}`, upsert);

        return result.data;
    }

    public static delete = async (id : number) : Promise<void> => {
        return await axios.delete(`${API_URL}/v1/MasterData/SafetyPrecautionPpe/Delete`, {
            params: { id },
        });
    }
}
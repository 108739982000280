import axios from 'axios';
import { IActivityUpsert } from '../../../@types/model/masterData/activity/activityUpsert';
import { IActivity } from '../../../@types/model/masterData/activity/activity';
import { UpsertTypeEnum } from '../../../@types/enum/upsertType';

export default class ActivityHttpService {
    public static getList = async (lastSyncedTimeUnix : number, signal ?: AbortSignal) : Promise<Array<IActivity>> => {
        const result = await axios.get(`${API_URL}/v1/MasterData/Activity/GetList`, {
            params: {
                lastSyncedTimeUnix
            },
            signal
        });

        return result.data;
    }

    public static upsert = async (upsert : IActivityUpsert, type : UpsertTypeEnum) : Promise<IActivity> => {
        const result = await axios.post(`${API_URL}/v1/MasterData/Activity/${type}`, upsert);

        return result.data;
    }

    public static delete = async (id : number) : Promise<void> => {
        return await axios.delete(`${API_URL}/v1/MasterData/Activity/Delete`, {
            params: { id },
        });
    }
}
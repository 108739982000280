import axios from 'axios';
import { IChemicalFormulation } from '../../../../@types/model/masterData/chemicals/chemicalFormulation/chemicalFormulation';
import { IChemicalFormulationUpsert } from '../../../../@types/model/masterData/chemicals/chemicalFormulation/chemicalFormulationUpsert';
import { UpsertTypeEnum } from '../../../../@types/enum/upsertType';

export default class ChemicalFormulationHttpService {
    public static getList = async (lastSyncedTimeUnix : number, signal ?: AbortSignal) : Promise<Array<IChemicalFormulation>> => {
        const result = await axios.get(`${API_URL}/v1/MasterData/ChemicalFormulation/GetList`, {
            params: {
                lastSyncedTimeUnix
            },
            signal
        });

        return result.data;
    }

    public static upsert = async (upsert : IChemicalFormulationUpsert, type : UpsertTypeEnum) : Promise<IChemicalFormulation> => {
        const result = await axios.post(`${API_URL}/v1/MasterData/ChemicalFormulation/${type}`, upsert);

        return result.data;
    }

    public static delete = async (id : number) : Promise<void> => {
        return await axios.delete(`${API_URL}/v1/MasterData/ChemicalFormulation/Delete`, {
            params: { id },
        });
    }
}
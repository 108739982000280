import { combineReducers } from 'redux';
import authReducer from './auth/reducer';
import generalReducer from './general/reducer';
import dataReducer from './data/reducer';
import freshServiceReducer from './freshService/reducer';
import rightReducer from './right/reducer';
import syncReducer from './syncing/reducer';
import { configureStore } from '@reduxjs/toolkit';
import { createHashHistory } from 'history';

export const history = createHashHistory();

export const createRootReducer = combineReducers({
    auth: authReducer,
    general: generalReducer,
    freshService: freshServiceReducer,
    right: rightReducer,
    data: dataReducer,
    sync: syncReducer
});

const store = configureStore({
    reducer: createRootReducer,
    devTools: process.env.NODE_ENV === 'development',
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }),
    // middleware: getDefaultMiddleware({
    //     serializableCheck: false,
    //     // immutableCheck: false
    // }),
});

export default store;

import axios from 'axios';
import { IActivityTypeUpsert } from '../../../@types/model/masterData/activityType/activityTypeUpsert';
import { IActivityType } from '../../../@types/model/masterData/activityType/activityType';
import { UpsertTypeEnum } from '../../../@types/enum/upsertType';

export default class ActivityTypeHttpService {
    public static getList = async (lastSyncedTimeUnix : number, signal ?: AbortSignal) : Promise<Array<IActivityType>> => {
        const result = await axios.get(`${API_URL}/v1/MasterData/ActivityType/GetList`, {
            params: {
                lastSyncedTimeUnix
            },
            signal
        });

        return result.data;
    }

    public static upsert = async (upsert : IActivityTypeUpsert, type : UpsertTypeEnum) : Promise<IActivityType> => {
        const result = await axios.post(`${API_URL}/v1/MasterData/ActivityType/${type}`, upsert);

        return result.data;
    }

    public static delete = async (id : number) : Promise<void> => {
        return await axios.delete(`${API_URL}/v1/MasterData/ActivityType/Delete`, {
            params: { id },
        });
    }
}
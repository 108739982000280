import axios from 'axios';
import { IActiveIngredient } from '../../../../@types/model/masterData/chemicals/activeIngredient/activeIngredient';
import { IActiveIngredientUpsert } from '../../../../@types/model/masterData/chemicals/activeIngredient/activeIngredientUpsert';
import { UpsertTypeEnum } from '../../../../@types/enum/upsertType';

export default class ActiveIngredientHttpService {
    public static getList = async (lastSyncedTimeUnix : number, signal ?: AbortSignal) : Promise<Array<IActiveIngredient>> => {
        const result = await axios.get(`${API_URL}/v1/MasterData/ActiveIngredient/GetList`, {
            params: {
                lastSyncedTimeUnix
            },
            signal
        });

        return result.data;
    }

    public static upsert = async (upsert : IActiveIngredientUpsert, type : UpsertTypeEnum) : Promise<IActiveIngredient> => {
        const result = await axios.post(`${API_URL}/v1/MasterData/ActiveIngredient/${type}`, upsert);

        return result.data;
    }

    public static delete = async (id : number) : Promise<void> => {
        return await axios.delete(`${API_URL}/v1/MasterData/ActiveIngredient/Delete`, {
            params: { id },
        });
    }
}
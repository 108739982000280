import axios from 'axios';
import { List } from 'lodash';
import { IIrrigationCycle } from '../../../@types/model/masterData/irrigationCycle/irrigationCycle';
import { IIrrigationCycleUpsert } from '../../../@types/model/masterData/irrigationCycle/irrigationCycleUpsert';
import { UpsertTypeEnum } from '../../../@types/enum/upsertType';

export default class IrrigationCycleHttpService {
    public static getList = async (lastSyncedTimeUnix : number, productionUnitIds : List<number> | null, signal ?: AbortSignal) : Promise<Array<IIrrigationCycle>> => {
        const result = await axios.get(`${API_URL}/v1/MasterData/IrrigationCycle/GetList`, {
            params: {
                lastSyncedTimeUnix,
                productionUnitIds
            },
            paramsSerializer: {
                indexes: null
            },
            signal
        });

        return result.data;
    }


    public static upsert = async (upsert : IIrrigationCycleUpsert, type : UpsertTypeEnum) : Promise<IIrrigationCycle> => {
        const result = await axios.post(`${API_URL}/v1/MasterData/IrrigationCycle/${type}`, upsert);

        return result.data;
    }

    public static delete = async (id : number) : Promise<void> => {
        return await axios.delete(`${API_URL}/v1/MasterData/irrigationCycle/Delete`, {
            params: { id },
        });
    }
}
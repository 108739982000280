import axios from 'axios';
import { ISprayMethod } from '../../../@types/model/masterData/sprayMethod/splayMethod';
import { ISprayMethodUpsert } from '../../../@types/model/masterData/sprayMethod/sprayMethodUpsert';
import { UpsertTypeEnum } from '../../../@types/enum/upsertType';

export default class SprayMethodHttpService {
    public static getList = async (lastSyncedTimeUnix : number, signal ?: AbortSignal) : Promise<Array<ISprayMethod>> => {
        const result = await axios.get(`${API_URL}/v1/MasterData/SprayMethod/GetList`, {
            params: {
                lastSyncedTimeUnix
            },
            signal
        });

        return result.data;
    }

    public static upsert = async (upsert : ISprayMethodUpsert, type : UpsertTypeEnum) : Promise<ISprayMethod> => {
        const result = await axios.post(`${API_URL}/v1/MasterData/SprayMethod/${type}`, upsert);

        return result.data;
    }

    public static delete = async (id : number) : Promise<void> => {
        return await axios.delete(`${API_URL}/v1/MasterData/SprayMethod/Delete`, {
            params: { id },
        });
    }
}
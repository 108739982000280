import { ICropLine } from './cropData/cropLine/cropLine';
import { IField } from './masterData/field/field';
import { IPlantsPerHectare } from './masterData/plantsPerHectare/plantsPerHectare';
import { IProject } from './masterData/project/project';
import { ISeedTraySize } from './masterData/seedTraySize/seedTraySize';
import { IBaseModel, IOptionType } from '@zz2/zz2-ui';
import lodash from 'lodash';


export class OptionType {
    public static fromDataModel = function<T extends IBaseModel>(dataModel : T) : IOptionType {
        let labelNameOrDescription = '';

        let codeAdded = false;
        let nameAdded = false;

        if ('code' in dataModel && typeof dataModel.code === 'string' && dataModel.code !== '') {
            labelNameOrDescription += `${dataModel.code} `;
            codeAdded = true;
        }

        if ('name' in dataModel && typeof dataModel.name === 'string' && dataModel.name !== '') {
            labelNameOrDescription += `${dataModel.name} `;
            nameAdded = true;
        }

        if ('description' in dataModel
            && typeof dataModel.description === 'string'
            && dataModel.description !== ''
            && (!codeAdded || !nameAdded)) { // We don't want to add a description if the code and name have already been added.
            labelNameOrDescription += `${dataModel.description} `;
        }

        if (labelNameOrDescription === '') {
            return {
                label: 'Label Not Found',
                value: dataModel.id,
            };
        }

        const stringPartitions = labelNameOrDescription.trim().split(' ');

        if (stringPartitions.length > 1) {
            const firstSection = lodash.first(stringPartitions) ?? '';
            const lastSection = labelNameOrDescription.substring(firstSection.length);

            return {
                label: `(${firstSection}) - ${lastSection}`,
                value: dataModel.id,
            };
        } else {
            return {
                label: labelNameOrDescription.trim(),
                value: dataModel.id,
            };
        }
    }

    /*================================================================================================================
*                                                 Custom Option Type Map
     * ==============================================================================================================*/

    public static fromField = (field : IField) : IOptionType => {
        return {
            label: `(${field.department?.code}) ${field.name}`,
            value: field.id,
        };
    };

    public static fromProject = (project : IProject) : IOptionType => {
        return {
            label: `(${project.commodity?.name ?? ''}) - ${project.number}`,
            value: project.id,
        };
    };

    public static fromPlantsPerHectare = (plantsPerHectare : IPlantsPerHectare) : IOptionType => {
        return {
            label: plantsPerHectare.numberOfPlants.toString(),
            value: plantsPerHectare.id,
        };
    };

    public static fromSeedTraySize = (seedTraySize : ISeedTraySize) : IOptionType => {
        return {
            label: seedTraySize.size,
            value: seedTraySize.id,
        };
    };

    public static fromCropLine = (cropLine : ICropLine) : IOptionType => {
        return {
            label: `(${cropLine.cropId}) - CropLine`,
            value: cropLine.id,
        };
    };
}

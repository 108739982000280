import axios from 'axios';
import { ISafetyPrecaution } from '../../../../@types/model/masterData/chemicals/safetyPrecaution/safetyPrecaution';
import { ISafetyPrecautionUpsert } from '../../../../@types/model/masterData/chemicals/safetyPrecaution/safetyPrecautionUpsert';
import { UpsertTypeEnum } from '../../../../@types/enum/upsertType';

export default class SafetyPrecautionHttpService {
    public static getList = async (lastSyncedTimeUnix : number, signal ?: AbortSignal) : Promise<Array<ISafetyPrecaution>> => {
        const result = await axios.get(`${API_URL}/v1/MasterData/SafetyPrecaution/GetList`, {
            params: {
                lastSyncedTimeUnix
            },
            signal
        });

        return result.data;
    }

    public static upsert = async (upsert : ISafetyPrecautionUpsert, type : UpsertTypeEnum) : Promise<ISafetyPrecaution> => {
        const result = await axios.post(`${API_URL}/v1/MasterData/SafetyPrecaution/${type}`, upsert);

        return result.data;
    }

    public static delete = async (id : number) : Promise<void> => {
        return await axios.delete(`${API_URL}/v1/MasterData/SafetyPrecaution/Delete`, {
            params: { id },
        });
    }
}
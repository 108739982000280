import { useQuery, useMutation, UseQueryResult } from '@tanstack/react-query';
import { queryErrorHandler, querySuccessHandler, queryDeleteSuccessHandler, queryUpdateSessionSuccessHandler } from '../queryStateHandlers';
import { DELETE_USER_KEY, GET_USERS_KEY, LINK_USER_GOOGLE_ACCOUNT, LOGOUT_USERS_KEY, UNLINK_USER_GOOGLE_ACCOUNT, UPSERT_USER_KEY } from '../queryConstants';
import { IUser } from '../../../@types/model/auth/user/user';
import { IUserUpsert } from '../../../@types/model/auth/user/userUpsert';
import UserHttpService from '../../../service/http/right/userHttpService';
import { IGoogleLogInView } from '../../../@types/model/auth/login/GoogleLogInView';
import { UpsertTypeEnum } from '../../../@types/enum/upsertType';
import AuthHttpService from '../../../service/http/authHttpService';

export const useGetUsers = (enabled ?: boolean) : UseQueryResult<Array<IUser>, Error> => {
    const query = useQuery<Array<IUser>, Error>({
        queryKey: [GET_USERS_KEY],
        queryFn: ({ signal }) => UserHttpService.getList(signal),
        onError: queryErrorHandler('Error loading users.'),
        enabled: enabled ?? true,
    });

    return query;
};

export const useHandleUserUpsert = () => {
    const result = useMutation<Array<IUser>, Error, { upsertList : Array<IUserUpsert>; type : UpsertTypeEnum }>({
        mutationKey: [UPSERT_USER_KEY],
        mutationFn: (args : { upsertList : Array<IUserUpsert>; type : UpsertTypeEnum }) => UserHttpService.upsert(args.upsertList, args.type),
        onError: queryErrorHandler('Error creating/updating user.'),
        onSuccess: querySuccessHandler([GET_USERS_KEY], 'User updated successfully.'),
    });

    return result;
};

export const useHandleUserDelete = () => {
    const result = useMutation<void, Error, number>({
        mutationKey: [DELETE_USER_KEY],
        mutationFn: (id : number) => UserHttpService.delete(id),
        onError: queryErrorHandler('Error deleting user.'),
        onSuccess: queryDeleteSuccessHandler([GET_USERS_KEY], 'User inactivated successfully.'),
    });

    return result;
};

export const useHandleLinkGoogleAccount = () => {
    const result = useMutation<IUser | null, Error, IGoogleLogInView>({
        mutationKey: [LINK_USER_GOOGLE_ACCOUNT],
        mutationFn: (googleLogin : IGoogleLogInView) => UserHttpService.linkGoogleAccount(googleLogin),
        onError: queryErrorHandler('Error encountered while trying to link Google Account.'),
        onSuccess: queryUpdateSessionSuccessHandler('Google Account linked successfully.')
    });

    return result;
};

export const useHandleUnlinkGoogleAccount = () => {
    const result = useMutation<IUser | null, Error, void>({
        mutationKey: [UNLINK_USER_GOOGLE_ACCOUNT],
        mutationFn: UserHttpService.unlinkGoogleAccount,
        onError: queryErrorHandler('Error encountered while trying to link Google Account.'),
        onSuccess: queryUpdateSessionSuccessHandler('Google Account unlinked successfully.')
    });

    return result;
};

export const useHandleLogoutUsers = () => {
    const result = useMutation<void, Error, Array<number>>({
        mutationKey: [LOGOUT_USERS_KEY],
        mutationFn: (userIds : Array<number>) => AuthHttpService.logoutUsers(userIds),
        // onError: Handled manually where this query is used,
        // onSuccess: Handled manually where this query is used
    });

    return result;
};
import { openDb } from 'idb';
import { upgradeDb } from './localStorageService';
import { IMasterDataSync } from '../@types/model/sync/masterDataSync';
import { IDivision } from '../@types/model/masterData/division/division';
import { ISubdivision } from '../@types/model/masterData/subdivision/subdivision';
import { IDepartment } from '../@types/model/masterData/department/department';
import { IProductionUnit } from '../@types/model/masterData/productionUnit/productionUnit';
import { IField } from '../@types/model/masterData/field/field';
import { IBlock } from '../@types/model/masterData/block/block';
import { ICommodity } from '../@types/model/masterData/commodity/commodity';
import { IVariety } from '../@types/model/masterData/variety/variety';
import { INursery } from '../@types/model/masterData/nursery/nursery';
import { IRootStock } from '../@types/model/masterData/rootStock/rootStock';
import { IProject } from '../@types/model/masterData/project/project';
import { IPlantsPerHectare } from '../@types/model/masterData/plantsPerHectare/plantsPerHectare';
import { ISafetyPrecaution } from '../@types/model/masterData/chemicals/safetyPrecaution/safetyPrecaution';
import { ISafetyPrecautionPpe } from '../@types/model/masterData/chemicals/safetyPrecautionPpe/safetyPrecautionPpe';
import { ISeedTraySize } from '../@types/model/masterData/seedTraySize/seedTraySize';
import { ISprayMethod } from '../@types/model/masterData/sprayMethod/splayMethod';
import { IUnitOfMeasure } from '../@types/model/masterData/unitOfMeasure/unitOfMeasure';
import { IActiveIngredient } from '../@types/model/masterData/chemicals/activeIngredient/activeIngredient';
import { IChemicalCategory } from '../@types/model/masterData/chemicals/chemicalCategory/chemicalCategory';
import { IChemicalElement } from '../@types/model/masterData/chemicals/chemicalElement/chemicalElement';
import { IChemicalFormulation } from '../@types/model/masterData/chemicals/chemicalFormulation/chemicalFormulation';
import { IChemicalProduct } from '../@types/model/masterData/chemicals/chemicalProduct/chemicalProduct';
import { IActivity } from '../@types/model/masterData/activity/activity';
import { ISystem } from '../@types/model/masterData/system/system';
import { IActivityType } from '../@types/model/masterData/activityType/activityType';
import { IPriority } from '../@types/model/masterData/priority/priority';
import { IBaseModel } from '@zz2/zz2-ui';
import { IIrrigationCycle } from '../@types/model/masterData/irrigationCycle/irrigationCycle';

// IndexedDB Master Data Object Store(table) names
export const LAST_SYNC_DATE_TIMES_TABLE = 'last-sync-date-time-table';

export const DIVISION_TABLE_NAME = 'division-table';
export const SUBDIVISION_TABLE_NAME = 'subdivision-table';
export const DEPARTMENT_TABLE_NAME = 'department-table';
export const PRODUCTION_UNIT_TABLE_NAME = 'production-unit-table';
export const FIELD_TABLE_NAME = 'field-table';
export const BLOCK_TABLE_NAME = 'block-table';
export const COMMODITY_TABLE_NAME = 'commodity-table';
export const VARIETY_TABLE_NAME = 'variety-table';
export const NURSERY_TABLE_NAME = 'nursery-table';
export const ROOT_STOCK_TABLE_NAME = 'root-stock-table';
export const PROJECT_TABLE_NAME = 'project-table';
export const PLANTS_PER_HECTARE_TABLE_NAME = 'plants-per-hectare-table';
export const SAFETY_PRECAUTION_TABLE_NAME = 'safety-precaution-table';
export const SAFETY_PRECAUTION_PPE_TABLE_NAME = 'safety-precaution-ppe-table';
export const SEED_TRAY_SIZE_TABLE_NAME = 'seed-tray-size-table';
export const SPRAY_METHOD_TABLE_NAME = 'spray-method-table';
export const UNIT_OF_MEASURE_TABLE_NAME = 'unit-of-measure-table';
export const ACTIVE_INGREDIENT_TABLE_NAME = 'active-ingredient-table';
export const CHEMICAL_CATEGORY_TABLE_NAME = 'chemical-category-table';
export const CHEMICAL_ELEMENT_TABLE_NAME = 'chemical-element-table';
export const CHEMICAL_FORMULATION_TABLE_NAME = 'chemical-formulation-table';
export const CHEMICAL_PRODUCT_TABLE_NAME = 'chemical-product-table';
export const ACTIVITY_TYPE_TABLE_NAME = 'activity-type-table';
export const ACTIVITY_TABLE_NAME = 'activity-table';
export const SYSTEM_TABLE_NAME = 'system-table';
export const PRIORITY_TABLE_NAME = 'priority-table';
export const IRRIGATION_CYCLE_TABLE_NAME = 'irrigation-cycle-table';

/* ****************************************************************************** */
/* Start of Master Data IndexedDB storage */

export async function clearIndexedDBObjectStores() : Promise<void> {
    const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

    // Gets transaction containing all master data object stores
    const tx = db.transaction([
        DIVISION_TABLE_NAME,
        SUBDIVISION_TABLE_NAME,
        DEPARTMENT_TABLE_NAME,
        PRODUCTION_UNIT_TABLE_NAME,
        FIELD_TABLE_NAME,
        BLOCK_TABLE_NAME,
        COMMODITY_TABLE_NAME,
        VARIETY_TABLE_NAME,
        NURSERY_TABLE_NAME,
        ROOT_STOCK_TABLE_NAME,
        PROJECT_TABLE_NAME,
        PLANTS_PER_HECTARE_TABLE_NAME,
        SAFETY_PRECAUTION_TABLE_NAME,
        SAFETY_PRECAUTION_PPE_TABLE_NAME,
        SEED_TRAY_SIZE_TABLE_NAME,
        SPRAY_METHOD_TABLE_NAME,
        UNIT_OF_MEASURE_TABLE_NAME,
        ACTIVE_INGREDIENT_TABLE_NAME,
        CHEMICAL_CATEGORY_TABLE_NAME,
        CHEMICAL_ELEMENT_TABLE_NAME,
        CHEMICAL_FORMULATION_TABLE_NAME,
        CHEMICAL_PRODUCT_TABLE_NAME,
        ACTIVITY_TYPE_TABLE_NAME,
        ACTIVITY_TABLE_NAME,
        SYSTEM_TABLE_NAME,
        PRIORITY_TABLE_NAME,
        IRRIGATION_CYCLE_TABLE_NAME,
    ], 'readwrite');

    // Gets each object store from the above transaction
    const divisionStore = tx.objectStore(DIVISION_TABLE_NAME);
    const subdivisionStore = tx.objectStore(SUBDIVISION_TABLE_NAME);
    const departmentStore = tx.objectStore(DEPARTMENT_TABLE_NAME);
    const productionUnitStore = tx.objectStore(PRODUCTION_UNIT_TABLE_NAME);
    const fieldStore = tx.objectStore(FIELD_TABLE_NAME);
    const blockStore = tx.objectStore(BLOCK_TABLE_NAME);
    const commodityStore = tx.objectStore(COMMODITY_TABLE_NAME);
    const varietyStore = tx.objectStore(VARIETY_TABLE_NAME);
    const nurseryStore = tx.objectStore(NURSERY_TABLE_NAME);
    const rootStockStore = tx.objectStore(ROOT_STOCK_TABLE_NAME);
    const projectStore = tx.objectStore(PROJECT_TABLE_NAME);
    const plantsPerHectareStore = tx.objectStore(PLANTS_PER_HECTARE_TABLE_NAME);
    const safetyPrecautionStore = tx.objectStore(SAFETY_PRECAUTION_TABLE_NAME);
    const safetyPrecautionPpeStore = tx.objectStore(SAFETY_PRECAUTION_PPE_TABLE_NAME);
    const seedTraySizeStore = tx.objectStore(SEED_TRAY_SIZE_TABLE_NAME);
    const SprayMethodStore = tx.objectStore(SPRAY_METHOD_TABLE_NAME);
    const unitOfMeasureStore = tx.objectStore(UNIT_OF_MEASURE_TABLE_NAME);
    const activeIngredientStore = tx.objectStore(ACTIVE_INGREDIENT_TABLE_NAME);
    const chemicalCategoryStore = tx.objectStore(CHEMICAL_CATEGORY_TABLE_NAME);
    const chemicalElementStore = tx.objectStore(CHEMICAL_ELEMENT_TABLE_NAME);
    const chemicalFormulationStore = tx.objectStore(CHEMICAL_FORMULATION_TABLE_NAME);
    const chemicalProductStore = tx.objectStore(CHEMICAL_PRODUCT_TABLE_NAME);
    const activityTypeStore = tx.objectStore(ACTIVITY_TYPE_TABLE_NAME);
    const activityStore = tx.objectStore(ACTIVITY_TABLE_NAME);
    const systemStore = tx.objectStore(SYSTEM_TABLE_NAME);
    const priorityStore = tx.objectStore(PRIORITY_TABLE_NAME);
    const irrigationCycleStore = tx.objectStore(IRRIGATION_CYCLE_TABLE_NAME);

    await Promise.all([
        divisionStore.clear(),
        subdivisionStore.clear(),
        departmentStore.clear(),
        productionUnitStore.clear(),
        fieldStore.clear(),
        blockStore.clear(),
        commodityStore.clear(),
        varietyStore.clear(),
        nurseryStore.clear(),
        rootStockStore.clear(),
        projectStore.clear(),
        plantsPerHectareStore.clear(),
        safetyPrecautionStore.clear(),
        safetyPrecautionPpeStore.clear(),
        seedTraySizeStore.clear(),
        SprayMethodStore.clear(),
        unitOfMeasureStore.clear(),
        activeIngredientStore.clear(),
        chemicalCategoryStore.clear(),
        chemicalElementStore.clear(),
        chemicalFormulationStore.clear(),
        chemicalProductStore.clear(),
        activityTypeStore.clear(),
        activityStore.clear(),
        systemStore.clear(),
        priorityStore.clear(),
        irrigationCycleStore.clear(),
    ]);

    await tx.complete;
}

/**
 * Updates all master data object stores in indexedDB with data returned from master data sync api call
 *
 */
export async function setIndexedDBMasterData(masterData : IMasterDataSync) : Promise<IMasterDataSync> {
    const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

    // Gets transaction containing all master data object stores
    const tx = db.transaction([
        DIVISION_TABLE_NAME,
        SUBDIVISION_TABLE_NAME,
        DEPARTMENT_TABLE_NAME,
        PRODUCTION_UNIT_TABLE_NAME,
        FIELD_TABLE_NAME,
        BLOCK_TABLE_NAME,
        COMMODITY_TABLE_NAME,
        VARIETY_TABLE_NAME,
        NURSERY_TABLE_NAME,
        ROOT_STOCK_TABLE_NAME,
        PROJECT_TABLE_NAME,
        PLANTS_PER_HECTARE_TABLE_NAME,
        SAFETY_PRECAUTION_TABLE_NAME,
        SAFETY_PRECAUTION_PPE_TABLE_NAME,
        SEED_TRAY_SIZE_TABLE_NAME,
        SPRAY_METHOD_TABLE_NAME,
        UNIT_OF_MEASURE_TABLE_NAME,
        ACTIVE_INGREDIENT_TABLE_NAME,
        CHEMICAL_CATEGORY_TABLE_NAME,
        CHEMICAL_ELEMENT_TABLE_NAME,
        CHEMICAL_FORMULATION_TABLE_NAME,
        CHEMICAL_PRODUCT_TABLE_NAME,
        ACTIVITY_TYPE_TABLE_NAME,
        ACTIVITY_TABLE_NAME,
        SYSTEM_TABLE_NAME,
        PRIORITY_TABLE_NAME,
        IRRIGATION_CYCLE_TABLE_NAME,
    ], 'readwrite');

    // Gets each object store from the above transaction
    const divisionStore = tx.objectStore(DIVISION_TABLE_NAME);
    const subdivisionStore = tx.objectStore(SUBDIVISION_TABLE_NAME);
    const departmentStore = tx.objectStore(DEPARTMENT_TABLE_NAME);
    const productionUnitStore = tx.objectStore(PRODUCTION_UNIT_TABLE_NAME);
    const fieldStore = tx.objectStore(FIELD_TABLE_NAME);
    const blockStore = tx.objectStore(BLOCK_TABLE_NAME);
    const commodityStore = tx.objectStore(COMMODITY_TABLE_NAME);
    const varietyStore = tx.objectStore(VARIETY_TABLE_NAME);
    const nurseryStore = tx.objectStore(NURSERY_TABLE_NAME);
    const rootStockStore = tx.objectStore(ROOT_STOCK_TABLE_NAME);
    const projectStore = tx.objectStore(PROJECT_TABLE_NAME);
    const plantsPerHectareStore = tx.objectStore(PLANTS_PER_HECTARE_TABLE_NAME);
    const safetyPrecautionStore = tx.objectStore(SAFETY_PRECAUTION_TABLE_NAME);
    const safetyPrecautionPpeStore = tx.objectStore(SAFETY_PRECAUTION_PPE_TABLE_NAME);
    const seedTraySizeStore = tx.objectStore(SEED_TRAY_SIZE_TABLE_NAME);
    const SprayMethodStore = tx.objectStore(SPRAY_METHOD_TABLE_NAME);
    const unitOfMeasureStore = tx.objectStore(UNIT_OF_MEASURE_TABLE_NAME);
    const activeIngredientStore = tx.objectStore(ACTIVE_INGREDIENT_TABLE_NAME);
    const chemicalCategoryStore = tx.objectStore(CHEMICAL_CATEGORY_TABLE_NAME);
    const chemicalElementStore = tx.objectStore(CHEMICAL_ELEMENT_TABLE_NAME);
    const chemicalFormulationStore = tx.objectStore(CHEMICAL_FORMULATION_TABLE_NAME);
    const chemicalProductStore = tx.objectStore(CHEMICAL_PRODUCT_TABLE_NAME);
    const activityTypeStore = tx.objectStore(ACTIVITY_TYPE_TABLE_NAME);
    const activityStore = tx.objectStore(ACTIVITY_TABLE_NAME);
    const systemStore = tx.objectStore(SYSTEM_TABLE_NAME);
    const priorityStore = tx.objectStore(PRIORITY_TABLE_NAME);
    const irrigationCycleStore = tx.objectStore(IRRIGATION_CYCLE_TABLE_NAME);

    /**
     * Updates each specific object store. Adding new entry if it does not exist
     * otherwise updating the existing one.
     */
    masterData.divisions.forEach((x) => {
        divisionStore.put(x, x.guid);
    });

    masterData.subdivisions.forEach((x) => {
        subdivisionStore.put(x, x.guid);
    });

    masterData.departments.forEach((x) => {
        departmentStore.put(x, x.guid);
    });

    masterData.productionUnits.forEach((x) => {
        productionUnitStore.put(x, x.guid);
    });

    masterData.fields.forEach((x) => {
        fieldStore.put(x, x.guid);
    });

    masterData.blocks.forEach((x) => {
        blockStore.put(x, x.guid);
    });

    masterData.commodities.forEach((x) => {
        commodityStore.put(x, x.guid);
    });

    masterData.varieties.forEach((x) => {
        varietyStore.put(x, x.guid);
    });

    masterData.nurseries.forEach((x) => {
        nurseryStore.put(x, x.guid);
    });

    masterData.rootStocks.forEach((x) => {
        rootStockStore.put(x, x.guid);
    });

    masterData.projects.forEach((x) => {
        projectStore.put(x, x.guid);
    });

    masterData.plantsPerHectares.forEach((x) => {
        plantsPerHectareStore.put(x, x.guid);
    });

    masterData.safetyPrecautions.forEach((x) => {
        safetyPrecautionStore.put(x, x.guid);
    });

    masterData.safetyPrecautionPpes.forEach((x) => {
        safetyPrecautionPpeStore.put(x, x.guid);
    });

    masterData.seedTraySizes.forEach((x) => {
        seedTraySizeStore.put(x, x.guid);
    });

    masterData.sprayMethods.forEach((x) => {
        SprayMethodStore.put(x, x.guid);
    });

    masterData.unitOfMeasures.forEach((x) => {
        unitOfMeasureStore.put(x, x.guid);
    });

    masterData.activeIngredients.forEach((x) => {
        activeIngredientStore.put(x, x.guid);
    });

    masterData.chemicalCategories.forEach((x) => {
        chemicalCategoryStore.put(x, x.guid);
    });

    masterData.chemicalElements.forEach((x) => {
        chemicalElementStore.put(x, x.guid);
    });

    masterData.chemicalFormulations.forEach((x) => {
        chemicalFormulationStore.put(x, x.guid);
    });

    masterData.chemicalProducts.forEach((x) => {
        chemicalProductStore.put(x, x.guid);
    });

    masterData.activityTypes.forEach((x) => {
        activityTypeStore.put(x, x.guid);
    });

    masterData.activities.forEach((x) => {
        activityStore.put(x, x.guid);
    });
    
    masterData.systems.forEach((x) => {
        systemStore.put(x, x.guid);
    });

    masterData.priorities.forEach((x) => {
        priorityStore.put(x, x.guid);
    });

    masterData.irrigationCycles.forEach((x) => {
        irrigationCycleStore.put(x, x.guid);
    });

    // Retrieving the new updated list
    const updatedDivisions = await tx.objectStore<IDivision>(DIVISION_TABLE_NAME).getAll();
    const updatedSubdivisions = await tx.objectStore<ISubdivision>(SUBDIVISION_TABLE_NAME).getAll();
    const updatedDepartments = await tx.objectStore<IDepartment>(DEPARTMENT_TABLE_NAME).getAll();
    const updatedProductionUnits = await tx.objectStore<IProductionUnit>(PRODUCTION_UNIT_TABLE_NAME).getAll();
    const updatedFields = await tx.objectStore<IField>(FIELD_TABLE_NAME).getAll();
    const updatedBlocks = await tx.objectStore<IBlock>(BLOCK_TABLE_NAME).getAll();
    const updatedCommodities = await tx.objectStore<ICommodity>(COMMODITY_TABLE_NAME).getAll();
    const updatedVarieties = await tx.objectStore<IVariety>(VARIETY_TABLE_NAME).getAll();
    const updatedNurseries = await tx.objectStore<INursery>(NURSERY_TABLE_NAME).getAll();
    const updatedRootStocks = await tx.objectStore<IRootStock>(ROOT_STOCK_TABLE_NAME).getAll();
    const updatedProjects = await tx.objectStore<IProject>(PROJECT_TABLE_NAME).getAll();
    const updatedPlantsPerHectares = await tx.objectStore<IPlantsPerHectare>(PLANTS_PER_HECTARE_TABLE_NAME).getAll();
    const updatedSafetyPrecautions = await tx.objectStore<ISafetyPrecaution>(SAFETY_PRECAUTION_TABLE_NAME).getAll();
    const updatedSafetyPrecautionPpes = await tx.objectStore<ISafetyPrecautionPpe>(SAFETY_PRECAUTION_PPE_TABLE_NAME).getAll();
    const updatedSeedTraySizes = await tx.objectStore<ISeedTraySize>(SEED_TRAY_SIZE_TABLE_NAME).getAll();
    const updatedSprayMethods = await tx.objectStore<ISprayMethod>(SPRAY_METHOD_TABLE_NAME).getAll();
    const updatedUnitOfMeasures = await tx.objectStore<IUnitOfMeasure>(UNIT_OF_MEASURE_TABLE_NAME).getAll();
    const updatedActiveIngredients = await tx.objectStore<IActiveIngredient>(ACTIVE_INGREDIENT_TABLE_NAME).getAll();
    const updatedChemicalCategories = await tx.objectStore<IChemicalCategory>(CHEMICAL_CATEGORY_TABLE_NAME).getAll();
    const updatedChemicalElements = await tx.objectStore<IChemicalElement>(CHEMICAL_ELEMENT_TABLE_NAME).getAll();
    const updatedChemicalFormulations = await tx.objectStore<IChemicalFormulation>(CHEMICAL_FORMULATION_TABLE_NAME).getAll();
    const updatedChemicalProducts = await tx.objectStore<IChemicalProduct>(CHEMICAL_PRODUCT_TABLE_NAME).getAll();
    const updatedActivityTypes = await tx.objectStore<IActivityType>(ACTIVITY_TYPE_TABLE_NAME).getAll();
    const updatedActivities = await tx.objectStore<IActivity>(ACTIVITY_TABLE_NAME).getAll();
    const updatedSystems = await tx.objectStore<ISystem>(SYSTEM_TABLE_NAME).getAll();
    const updatedPriorities = await tx.objectStore<IPriority>(PRIORITY_TABLE_NAME).getAll();
    const updatedIrrigationCycles = await tx.objectStore<IIrrigationCycle>(IRRIGATION_CYCLE_TABLE_NAME).getAll();

    // Creating new master data object with new updated lists to be returned so that redux can be updated.
    const updatedMasterData : IMasterDataSync = {
        divisions: updatedDivisions,
        subdivisions: updatedSubdivisions,
        departments: updatedDepartments,
        productionUnits: updatedProductionUnits,
        fields: updatedFields,
        blocks: updatedBlocks,
        commodities: updatedCommodities,
        varieties: updatedVarieties,
        nurseries: updatedNurseries,
        rootStocks: updatedRootStocks,
        projects: updatedProjects,
        plantsPerHectares: updatedPlantsPerHectares,
        safetyPrecautions: updatedSafetyPrecautions,
        safetyPrecautionPpes: updatedSafetyPrecautionPpes,
        seedTraySizes: updatedSeedTraySizes,
        sprayMethods: updatedSprayMethods,
        unitOfMeasures: updatedUnitOfMeasures,
        activeIngredients: updatedActiveIngredients,
        chemicalCategories: updatedChemicalCategories,
        chemicalElements: updatedChemicalElements,
        chemicalFormulations: updatedChemicalFormulations,
        chemicalProducts: updatedChemicalProducts,
        activityTypes: updatedActivityTypes,
        activities: updatedActivities,
        systems: updatedSystems,
        priorities: updatedPriorities,
        irrigationCycles: updatedIrrigationCycles,
    };

    await tx.complete;

    return updatedMasterData;
}

/*================================================================================================================
 *                                   Master Data IndexedDB Getters And Setters
 * ==============================================================================================================*/

/*---------------------------------------------Farm Master Data------------------------------------------------- */

/**
 * Populates given object store with provided list. Adding new record or updating if the record already exists
 *
 */
export async function setLastSyncDateTime(tableName : string, lastSyncDate : string) : Promise<void> {
    if (tableName === '' || tableName === ' ') return;

    // checks if indexedDB is available.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(LAST_SYNC_DATE_TIMES_TABLE, 'readwrite');

        const store = tx.objectStore(LAST_SYNC_DATE_TIMES_TABLE);

        store.put(lastSyncDate, tableName);

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves divisions.
 */
export async function getLastSyncDateTime(tableName : string) : Promise<string | null> {
    // checks if indexedDB is available.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(LAST_SYNC_DATE_TIMES_TABLE, 'readonly');

        const result = tx.objectStore<string>(LAST_SYNC_DATE_TIMES_TABLE).get(tableName);

        await tx.complete;

        return result;
    } else {
        return null;
    }
}

/**
 * Populates given object store with provided list. Adding new record or updating if the record already exists
 *
 */
export async function setData<T extends IBaseModel>(tableName : string, data : Array<T>) : Promise<Array<T> | []> {
    if (tableName === '' || tableName === ' ') return [];
    if (data.length < 1) return [];

    // checks if indexedDB is available.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(tableName, 'readwrite');

        const store = tx.objectStore(tableName);

        data.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;

        return [];
    } else {
        return data;
    }
}

/**
 * Opens the DB and retrieves divisions.
 */
export async function getData<T extends IBaseModel>(tableName : string) : Promise<Array<T>> {
    // checks if indexedDB is available.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(tableName, 'readonly');

        const result = tx.objectStore<T>(tableName).getAll();

        await tx.complete;

        return result;
    } else {
        return [];
    }
}

import React, { useEffect, useMemo, useState } from 'react';
import { IUser } from '../../@types/model/auth/user/user';
import { useAppDispatch, useAppSelector } from '../../@types/redux';
import useFontAwesome from '../../hooks/useFontAwesome';
import { useLocation } from 'react-router';
import GeneralActions from '../../store/general/actions';
import AuthThunk from '../../store/auth/thunk';
import UserHelper from '../../service/helper/userHelper';
import { IRight } from '../../@types/model/auth/right/right';
import UserSettings from './UserSettings';
import { CustomTooltip, Loader, NavBar, NavDrawer, SideMenuItem, lowercase } from '@zz2/zz2-ui';
import { VERSION } from '../../version';
import Routes from './Routes';
import { IOptionType } from '@zz2/zz2-ui';
import { Link } from 'react-router-dom';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import lodash from 'lodash';
import FreshServiceTicketCreationDialog from '../freshService/FreshServiceTicketCreationDialog';
import FreshServiceActions from '../../store/freshService/actions';
import { useGetRights } from '../../hooks/query/rights/rightQueries';
import { RIGHT_GRANTS } from '../../appConstants';
import EnvHelper from '../../service/helper/envHelper';
import { OptionType } from '../../@types/model/optionType';
import * as localStorageService from '../../service/localStorageService';
import DataActions from '../../store/data/actions';

const iconsLocation = `${ASSET_BASE}/assets/icons`;

const NavFrame = () : React.ReactElement => {
    useFontAwesome();

    const dispatch = useAppDispatch();
    const path = useLocation().pathname;

    const currentUser = useAppSelector<IUser | null>(x => x.auth.session?.user ?? null);
    const isMasterDataSyncRunning = useAppSelector(x => x.data.masterDataSyncIsLoading);
    const isNavDrawerOpen = useAppSelector(x => x.general.isNavDrawerOpen);
    const freshTicketServiceDescription = useAppSelector(x => x.freshService.supportTicketDescription);
    const isFreshServiceTicketDialogOpen = useAppSelector(x => x.freshService.isSupportTicketDialogOpen);

    const userSelectedDivisions : Array<IOptionType> = localStorageService.getUserSelectedDivisionsLocalStorage();
    const userSelectedDepartments : Array<IOptionType> = localStorageService.getUserSelectedDepartmentsLocalStorage();

    const [isUserSettingsDialogOpen, setIsUserSettingsDialogOpen] = useState<boolean>(false);

    /*================================================================================================================
     *                                                  Queries
     * ==============================================================================================================*/

    const { isLoading: isLoadingRights, data: rightData, refetch: loadRights } = useGetRights(false);

    /*================================================================================================================
     *                                              Effects
     * ==============================================================================================================*/

    useEffect(() => {    
        loadData();
    }, []);

    useEffect(() => {
        if (isNavDrawerOpen) {
            loadRights();
        }
    }, [isNavDrawerOpen]);

    useEffect(() => {
        if (!currentUser) return;
    
        if (currentUser.divisionIds !== null && currentUser.divisionIds.length > 0) {
            const selectedDivisions : Array<IOptionType> = currentUser.userDivisions
                .filter(x => x.isActive && !!x.division?.isActive)
                .map(OptionType.fromDataModel);
            localStorageService.setUserSelectedDivisionsLocalStorage(selectedDivisions);
            dispatch(DataActions.setSelectedUserDivisionIds(currentUser.divisionIds));
        }

        if (currentUser.departmentIds !== null && currentUser.departmentIds.length > 0) {
            const selectedDepartments = currentUser.userDepartments
                .filter(x => x.isActive && !!x.department?.isActive)
                .map(OptionType.fromDataModel);
            localStorageService.setUserSelectedDepartmentsLocalStorage(selectedDepartments);
            dispatch(DataActions.setSelectedUserDepartmentIds(currentUser.departmentIds));
        }
    }, [currentUser, currentUser?.divisionIds, currentUser?.departmentIds]);

    /*================================================================================================================
     *                                                  Async Methods
     * ==============================================================================================================*/

    const loadData = () : void => {
        if (currentUser) {
            if (userSelectedDivisions.length < 1 || userSelectedDepartments.length < 1) {
                setIsUserSettingsDialogOpen(true);
            }
        }
    };

    const signOut = () : void => {
        dispatch(AuthThunk.logOut());
        dispatch(GeneralActions.setNavDrawer(false));
    };

    const openUserSettings = () : void => {
        setIsUserSettingsDialogOpen(true);
    };

    /*================================================================================================================
     *                                          Handler Methods - NavBar & Drawer
     * ==============================================================================================================*/

    const openDrawer = () : void => {
        if (isMasterDataSyncRunning) return;
        dispatch(GeneralActions.setNavDrawer(true));
    };

    const closeDrawer = () : void => {
        if (isMasterDataSyncRunning) return;
        dispatch(GeneralActions.setNavDrawer(false));
    };

    const closeUserSettings = () : void => {
        setIsUserSettingsDialogOpen(false);
    };

    const updateVersion = () : void => {
        console.log('update version script to be added here.');
    };

    const handleFreshServiceTicketDialogOpen = () : void => {
        dispatch(FreshServiceActions.setIsSupportTicketDialogOpen(true));
    };

    const handleFreshServiceTicketDialogClose = () : void => {
        dispatch(FreshServiceActions.setIsSupportTicketDialogOpen(false));
    };

    const requestAccess = (right : IRight) : void => {
        const regex = new RegExp('/', 'g');
        const envTitle = ENV_NAME === 'production' ? 'FARM Website' : 'FARM QA Website';
        const title = (envTitle + '/' + VERSION.version).replace(regex, ' - ');

        dispatch(FreshServiceActions.setIsSupportTicketDialogOpen(true));
        dispatch(FreshServiceActions.setSupportTicketTitle(title));
        dispatch(FreshServiceActions.setSupportTicketDescription(`Requesting permission: ${right.name} - View.`));
    };

    const isParentOfCurrentPage = (parentId : number, rights ?: Array<IRight>, pathname ?: string) : boolean => {
        const currentPageRight = rights?.find(x => x.url === pathname);
        const parentRight = rights?.find(x => x.id === parentId);
    
        if (currentPageRight?.parentId) {
            return (currentPageRight.parentId === parentId) || (!!parentRight?.parentId && isParentOfCurrentPage(parentRight.parentId, rights, pathname));
        }
        return false;
    };

    /*================================================================================================================
     *                                                  Memos
     * ==============================================================================================================*/

    const divisionHeading = useMemo<string>(() => {
        if (!currentUser) return '';
        return UserHelper.getUserDivisionHeading(currentUser);
    }, [currentUser, userSelectedDivisions, isUserSettingsDialogOpen]);

    const departmentHeading = useMemo<string>(() => {
        if (!currentUser) return '';
        return UserHelper.getUserDepartmentHeading(currentUser);
    }, [currentUser, userSelectedDivisions, userSelectedDepartments, isUserSettingsDialogOpen]);

    const rights = useMemo<Array<IRight>>(() => {
        return currentUser?.rights ?? [];
    }, [currentUser]);

    const userCurrentRight = useMemo<IRight | undefined>(() => {
        return currentUser?.rights.filter(x => x.isActive)?.find(x => x.url === path);
    }, [currentUser, path]);

    const breadcrumbs = useMemo<Array<{ name : string; url : string }>>(() => {
        const breadcrumbList : Array<{ name : string; url : string }> = [];

        if (userCurrentRight) {
            let currentRight : IRight | undefined = userCurrentRight;

            do {
                breadcrumbList.push({ name: currentRight.name, url: currentRight.url ?? '' });
                currentRight = rights.find(x => x.id === currentRight?.breadcrumbParentId);
            } while (currentRight);
        }
        return breadcrumbList.reverse();
    }, [currentUser, location, rights, userCurrentRight]);

    const userSettings = useMemo<React.ReactElement>(() => {
        if (!isUserSettingsDialogOpen) return <div/>;
        return <UserSettings 
            isOpen={isUserSettingsDialogOpen}
            currentUser={currentUser ?? null}
            setIsUserDialogOpenCallback={setIsUserSettingsDialogOpen}
            onClose={closeUserSettings}
            signOut={signOut}
        />;
    }, [isUserSettingsDialogOpen]);

    /*================================================================================================================
     *                                                  Render Methods
     * ==============================================================================================================*/

    const renderListSection = (right : IRight, children : Array<IRight>, rights ?: Array<IRight>, pathname ?: string) : JSX.Element => {
        const userRights = currentUser?.userRights;
        const hasViewRight = userRights?.some(x =>
            x.isActive
            && x.right.id === right.id
            && x.rightGrantLevel === RIGHT_GRANTS.View);

        return (
            <CustomTooltip
                title={!hasViewRight ? UserHelper.getRelatedRightToolTipMessage(right.name, RIGHT_GRANTS.View) : ''}
                onClick={() : void => requestAccess(right)}
                rightValidation={!hasViewRight}
                key={'section_custom_tooltip_' + right.id}>
                <SideMenuItem
                    className={'pt10'}
                    paddingLeft={0}
                    icon={<img className={'h30 w30'} src={`${iconsLocation}/${lowercase(right.name).replace(/ /g, '_')}.svg`}/> }
                    title={right.name}
                    key={'section_' + right.id}
                    boldTitle={isParentOfCurrentPage(right.id, rights, pathname)}
                    disabled={!hasViewRight}>
                    {children.map(x => renderListItem(x, false, pathname))}
                </SideMenuItem>
            </CustomTooltip>
        );
    };

    const renderListItem = (right : IRight, hasIcon : boolean, pathname ?: string) : JSX.Element => {
        const userRights = currentUser?.userRights;
        const hasViewRight = userRights?.some(x =>
            x.isActive
            && x.right.id === right.id
            && x.rightGrantLevel === RIGHT_GRANTS.View);

        return (
            <CustomTooltip
                title={!hasViewRight ? UserHelper.getRelatedRightToolTipMessage(right.name, RIGHT_GRANTS.View) : ''}
                onClick={() : void => requestAccess(right)}
                rightValidation={!hasViewRight}
                key={'list_item_custom_tooltip_' + right.id}>
                <Link key={'list_item_link_' + right.id} to={right.url ?? ''} onClick={closeDrawer} style={{ pointerEvents: hasViewRight ? 'auto' : 'none', textDecoration: 'unset', color: 'unset' }}>
                    <ListItemButton
                        key={right.guid}
                        disabled={!hasViewRight}>
                        {
                            hasIcon ?
                                <ListItemIcon>
                                    {<img className={'h30 w30'} src={`${iconsLocation}/${lowercase(right.name).replace(/ /g, '_')}.svg`} />}
                                </ListItemIcon>
                                :
                                <div className={'h30 w30'} />
                        }
                        <ListItemText disableTypography className={`${pathname === right.url ? 'fw550' : ''} pl0`} inset primary={right.name} />
                    </ListItemButton>
                </Link>
            </CustomTooltip>
        );
    };

    const sections = useMemo<Array<JSX.Element>>(() => {
        const currentUserRights = currentUser?.rights ?? [];

        if (isLoadingRights) return [<Loader key={'loader'}/>];

        if (!rightData) return [];

        const result = lodash.chain(rightData)
            .filter(x => x.isOnNavDrawer && x.isActive && !x.parentId)
            .filter(x => !!x.sectionOrder)
            .sortBy(x => x.sectionOrder)
            .map((x) => {
                const userRights = currentUser?.userRights;
                const hasParentViewRight = userRights?.some(userRight =>
                    userRight.isActive
                    && userRight.right.id === x.id
                    && userRight.rightGrantLevel === RIGHT_GRANTS.View) ?? false;

                return {
                    hasParentViewRight,
                    section: x,
                    children: lodash.chain(rightData)
                        .filter(y => y.isOnNavDrawer && y.isActive && y.parentId === x.id)
                        .filter(y => !!y.pageOrder)
                        .sortBy(y => y.pageOrder)
                        .map((child) => {
                            const hasChildViewRight = userRights?.some(userRight =>
                                userRight.isActive
                                && userRight.right.id === x.id
                                && userRight.rightGrantLevel === RIGHT_GRANTS.View) ?? false;

                            return {
                                hasChildViewRight,
                                child,
                            };
                        })
                        .orderBy(x => x.hasChildViewRight, 'desc')
                        .value(),
                };
            })
            .orderBy(x => x.hasParentViewRight, 'desc')
            .map((x) => {
                return x.section.isPage
                    ? renderListItem(x.section, true, path)
                    : renderListSection(x.section, x.children.map(x => x.child), currentUserRights, path);
            }).value();
        
        return result;
    }, [currentUser, rightData, path, isLoadingRights]);

    return ( 
        <div className={'fdc hfill'}>
            <NavBar
                env={EnvHelper.getEnvName()}
                version={VERSION.version}
                currentUser={currentUser}
                signOut={signOut}
                breadcrumbs={breadcrumbs}
                updateVersion={updateVersion}
                openDrawer={openDrawer}
                closeDrawer={closeDrawer}
                openUserSettings={openUserSettings}
                closeUserSettings={closeUserSettings}
                isLatestVersion={true}  
                headings={[divisionHeading, departmentHeading]}
                disabled={isMasterDataSyncRunning}
                disabledTooltip={'Master Data is being synced.'}        
                userSettingsDialog={userSettings}             
            />
            <div className={'fdr flx1 oyh'}>
                {isNavDrawerOpen &&
                    <NavDrawer
                        env={EnvHelper.getEnvName()} 
                        path={path}
                        currentUser={currentUser}
                        isOpen={isNavDrawerOpen}
                        isLoading={isMasterDataSyncRunning || isLoadingRights}
                        sections={sections}
                        logOut={signOut}
                        closeDrawer={closeDrawer}
                        onHelpClick={handleFreshServiceTicketDialogOpen}
                    />
                }
                { 
                    isMasterDataSyncRunning 
                        ? <Loader /> 
                        : <Routes />
                }              
            </div>
            {isFreshServiceTicketDialogOpen &&
                <FreshServiceTicketCreationDialog 
                    isOpen={isFreshServiceTicketDialogOpen}
                    onDismiss={handleFreshServiceTicketDialogClose}
                    description={freshTicketServiceDescription}
                />
            }
        </div>
    );
};

export default NavFrame;
import moment from 'moment';
import {
    ACTIVE_INGREDIENT_TABLE_NAME,
    ACTIVITY_TABLE_NAME,
    ACTIVITY_TYPE_TABLE_NAME,
    BLOCK_TABLE_NAME,
    CHEMICAL_CATEGORY_TABLE_NAME,
    CHEMICAL_ELEMENT_TABLE_NAME,
    CHEMICAL_FORMULATION_TABLE_NAME,
    CHEMICAL_PRODUCT_TABLE_NAME,
    COMMODITY_TABLE_NAME,
    DEPARTMENT_TABLE_NAME,
    DIVISION_TABLE_NAME,
    FIELD_TABLE_NAME,
    getData,
    getLastSyncDateTime,
    IRRIGATION_CYCLE_TABLE_NAME,
    NURSERY_TABLE_NAME,
    PLANTS_PER_HECTARE_TABLE_NAME,
    PRIORITY_TABLE_NAME,
    PRODUCTION_UNIT_TABLE_NAME,
    PROJECT_TABLE_NAME,
    ROOT_STOCK_TABLE_NAME,
    SAFETY_PRECAUTION_PPE_TABLE_NAME,
    SAFETY_PRECAUTION_TABLE_NAME,
    SEED_TRAY_SIZE_TABLE_NAME,
    setData,
    setLastSyncDateTime,
    SPRAY_METHOD_TABLE_NAME,
    SUBDIVISION_TABLE_NAME,
    SYSTEM_TABLE_NAME,
    UNIT_OF_MEASURE_TABLE_NAME,
    VARIETY_TABLE_NAME } from '../service/masterDataSyncService';
import GeneralThunk from '../store/general/thunk';
import DivisionHttpService from '../service/http/masterData/divisionHttpService';
import SubdivisionHttpService from '../service/http/masterData/subdivisionHttpService';
import DepartmentHttpService from '../service/http/masterData/departmentHttpService';
import { IBaseModel } from '@zz2/zz2-ui';
import { IQueryParams } from '../@types/model/queryParams/queryParams';
import ProductionUnitHttpService from '../service/http/masterData/productionUnitHttpService';
import FieldHttpService from '../service/http/masterData/fieldHttpService';
import BlockHttpService from '../service/http/masterData/blockHttpService';
import CommodityHttpService from '../service/http/masterData/commodityHttpService';
import VarietyHttpService from '../service/http/masterData/varietyHttpService';
import ProjectHttpService from '../service/http/masterData/projectHttpService';
import NurseryHttpService from '../service/http/masterData/nurseryHttpService';
import RootStockHttpService from '../service/http/masterData/rootStockHttpService';
import PlantsPerHectareHttpService from '../service/http/masterData/plantsPerHectareHttpService';
import SeedTraySizeHttpService from '../service/http/masterData/seedTraySizeHttpService';
import SprayMethodHttpService from '../service/http/masterData/sprayMethodHttpService';
import UnitOfMeasureHttpService from '../service/http/masterData/unitOfMeasureHttpService';
import ActivityHttpService from '../service/http/masterData/activityHttpService';
import ActivityTypeHttpService from '../service/http/masterData/activityTypeHttpService';
import SystemHttpService from '../service/http/masterData/systemHttpService';
import PriorityHttpService from '../service/http/masterData/priorityHttpService';
import IrrigationCycleHttpService from '../service/http/masterData/irrigationCycleHttpService';
import SafetyPrecautionHttpService from '../service/http/masterData/chemicals/safetyPrecautionHttpService';
import SafetyPrecautionPpeHttpService from '../service/http/masterData/chemicals/safetyPrecautionPpeHttpService';
import ActiveIngredientHttpService from '../service/http/masterData/chemicals/activeIngredientHttpService';
import ChemicalCategoryHttpService from '../service/http/masterData/chemicals/chemicalCategoryHttpService';
import ChemicalElementHttpService from '../service/http/masterData/chemicals/chemicalElementHttpService';
import ChemicalFormulationHttpService from '../service/http/masterData/chemicals/chemicalFormulationHttpService';
import ChemicalProductHttpService from '../service/http/masterData/chemicals/chemicalProductHttpService';

export default class QueryMiddleware {
    public static getSyncList = async <T extends IBaseModel>(tableName : string,
        dispatch : any,
        params : IQueryParams | null,
        signal ?: AbortSignal) : Promise<Array<T>> => {

        let resultList : Array<T> = [];

        try {
            // Retrieved last synced value from local storage
            const lastSyncDate : string | null = await getLastSyncDateTime(tableName);
            const lastSyncDateUnix = lastSyncDate ? moment(lastSyncDate).unix() * 1000 : 0;

            const res = await QueryMiddleware.getHttpService(tableName, lastSyncDateUnix, params, dispatch, signal);
            resultList = res.length > 0 ? res.map(x => x as T) : [];
        } catch (e) {
            /**
             * Only show error if there is actually an error.
             * Cancellation errors are empty.
             */
            if (e) {
                dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: `An error occurred while loading data for ${tableName}.`, ex: e }));
            }
            return [];
        }

        try {
            await setData(tableName, resultList);
        } catch {
            dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: `Failed to update list in indexedDB for ${tableName}.` }));
        }

        try {
            const newMasterDataLastSyncDate : moment.Moment = moment().utc().subtract(1, 'hours');

            // Update last sync value to new value
            await setLastSyncDateTime(tableName, newMasterDataLastSyncDate.toString());
        } catch {
            dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: `Failed to update last sync date time in indexedDB for ${tableName}.` }));
        }

        try {
            return await getData(tableName);
        } catch {
            dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: `Failed to load from ${tableName} in indexedDB.` }));
        }

        return resultList.length > 0 ? resultList.map(x => x as T) : [];
    }

    public static getHttpService = async (tableName : string, lastSyncUnix : number, params : IQueryParams | null, dispatch : any, signal ?: AbortSignal) : Promise<Array<unknown>> => {
        switch (tableName) {
            case DIVISION_TABLE_NAME:
                if (!params?.divisionParams) {
                    dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'Missing division get list parameters.' }));
                    return [];
                }
                return await DivisionHttpService.getList(lastSyncUnix, params.divisionParams.divisionIds, signal);
            case SUBDIVISION_TABLE_NAME:
                if (!params?.subdivisionParams) {
                    dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'Missing subdivision get list parameters.' }));
                    return [];
                }
                return await SubdivisionHttpService.getList(lastSyncUnix, params.subdivisionParams.divisionIds, signal);
            case DEPARTMENT_TABLE_NAME:
                if (!params?.departmentParams) {
                    dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'Missing department get list parameters.' }));
                    return [];
                }
                return await DepartmentHttpService.getList(lastSyncUnix, params.departmentParams.departmentIds, params.departmentParams.subdivisionId, signal);
            case PRODUCTION_UNIT_TABLE_NAME:
                return await ProductionUnitHttpService.getList(lastSyncUnix);
            case FIELD_TABLE_NAME:
                if (!params?.fieldParams) {
                    dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'Missing field get list parameters.' }));
                    return [];
                }
                return await FieldHttpService.getList(lastSyncUnix, params.fieldParams.departmentIds, params.fieldParams.productionUnitIds, signal);
            case BLOCK_TABLE_NAME:
                if (!params?.blockParams) {
                    dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'Missing block get list parameters.' }));
                    return [];
                }
                return await BlockHttpService.getList(
                    lastSyncUnix,
                    params.blockParams.departmentIds,
                    params.blockParams.divisionId,
                    params.blockParams.fieldId,
                    params.blockParams.projectId,
                    signal);
            case COMMODITY_TABLE_NAME:
                return await CommodityHttpService.getList(lastSyncUnix, signal);
            case VARIETY_TABLE_NAME:
                return await VarietyHttpService.getList(lastSyncUnix, signal);
            case PROJECT_TABLE_NAME:
                return await ProjectHttpService.getList(lastSyncUnix, signal);
            case NURSERY_TABLE_NAME:
                return await NurseryHttpService.getList(lastSyncUnix, signal);
            case ROOT_STOCK_TABLE_NAME:
                return await RootStockHttpService.getList(lastSyncUnix, signal);
            case PLANTS_PER_HECTARE_TABLE_NAME:
                return await PlantsPerHectareHttpService.getList(lastSyncUnix, signal);
            case SEED_TRAY_SIZE_TABLE_NAME:
                return await SeedTraySizeHttpService.getList(lastSyncUnix, signal);
            case SPRAY_METHOD_TABLE_NAME:
                return await SprayMethodHttpService.getList(lastSyncUnix, signal);
            case UNIT_OF_MEASURE_TABLE_NAME:
                return await UnitOfMeasureHttpService.getList(lastSyncUnix, signal);
            case ACTIVITY_TABLE_NAME:
                return await ActivityHttpService.getList(lastSyncUnix, signal);
            case ACTIVITY_TYPE_TABLE_NAME:
                return await ActivityTypeHttpService.getList(lastSyncUnix, signal);
            case SYSTEM_TABLE_NAME:
                return await SystemHttpService.getList(lastSyncUnix, signal);
            case PRIORITY_TABLE_NAME:
                return await PriorityHttpService.getList(lastSyncUnix, signal);
            case IRRIGATION_CYCLE_TABLE_NAME:
                if (!params?.irrigationCycleParams) {
                    dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'Missing irrigation cycle get list parameters.' }));
                    return [];
                }
                return await IrrigationCycleHttpService.getList(lastSyncUnix, params.irrigationCycleParams.productionUnitIds, signal);
            case SAFETY_PRECAUTION_TABLE_NAME:
                return await SafetyPrecautionHttpService.getList(lastSyncUnix, signal);
            case SAFETY_PRECAUTION_PPE_TABLE_NAME:
                return await SafetyPrecautionPpeHttpService.getList(lastSyncUnix, signal);
            case ACTIVE_INGREDIENT_TABLE_NAME:
                return await ActiveIngredientHttpService.getList(lastSyncUnix, signal);
            case CHEMICAL_CATEGORY_TABLE_NAME:
                return await ChemicalCategoryHttpService.getList(lastSyncUnix, signal);
            case CHEMICAL_ELEMENT_TABLE_NAME:
                return await ChemicalElementHttpService.getList(lastSyncUnix, signal);
            case CHEMICAL_FORMULATION_TABLE_NAME:
                return await ChemicalFormulationHttpService.getList(lastSyncUnix, signal);
            case CHEMICAL_PRODUCT_TABLE_NAME:
                return await ChemicalProductHttpService.getList(lastSyncUnix, signal);
            default:
                return [];
        }
    }
}
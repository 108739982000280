import axios from 'axios';
import { ISubdivision } from '../../../@types/model/masterData/subdivision/subdivision';
import { ISubdivisionUpsert } from '../../../@types/model/masterData/subdivision/subdivisionUpsert';
import { UpsertTypeEnum } from '../../../@types/enum/upsertType';

export default class SubdivisionHttpService {
    public static getList = async (lastSyncedTimeUnix : number, divisionIds : Array<number> | null, signal ?: AbortSignal) : Promise<Array<ISubdivision>> => {
        if (!divisionIds || divisionIds.length < 1) return [];

        const result = await axios.get(`${API_URL}/v1/MasterData/Subdivision/GetList`, {
            params: {
                lastSyncedTimeUnix,
                divisionIds
            },
            paramsSerializer: {
                indexes: null
            },
            signal
        });

        return result.data;
    }

    public static upsert = async (upsert : ISubdivisionUpsert, type : UpsertTypeEnum) : Promise<ISubdivision> => {
        const result = await axios.post(`${API_URL}/v1/MasterData/Subdivision/${type}`, upsert);

        return result.data;
    }

    public static delete = async (id : number) : Promise<void> => {
        return await axios.delete(`${API_URL}/v1/MasterData/Subdivision/Delete`, {
            params: { id },
        });
    }
}
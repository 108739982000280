import { useMutation, useQuery, UseQueryResult } from '@tanstack/react-query';
import { queryDeleteMasterDataSuccessHandler, queryErrorHandler, queryMasterDataSuccessHandler } from '../queryStateHandlers';
import { IDepartment } from '../../../@types/model/masterData/department/department';
import { DELETE_DEPARTMENT_KEY, GET_DEPARTMENTS_KEY, UPSERT_DEPARTMENT_KEY } from '../queryConstants';
import { DEPARTMENT_TABLE_NAME } from '../../../service/masterDataSyncService';
import QueryMiddleware from '../../../middleware/queryMiddleware';
import { useAppDispatch } from '../../../@types/redux';
import { IDepartmentUpsert } from '../../../@types/model/masterData/department/departmentUpsert';
import DepartmentHttpService from '../../../service/http/masterData/departmentHttpService';
import { IDepartmentQueryParams } from '../../../@types/model/queryParams/masterDataQueryParams';
import { UpsertTypeEnum } from '../../../@types/enum/upsertType';

export const useGetDepartments = (params : IDepartmentQueryParams, enabled ?: boolean) : UseQueryResult<Array<IDepartment>, Error> => {
    const dispatch = useAppDispatch();
    const query = useQuery<Array<IDepartment>, Error>({
        queryKey: [GET_DEPARTMENTS_KEY],
        queryFn: ({ signal }) => QueryMiddleware.getSyncList<IDepartment>(DEPARTMENT_TABLE_NAME, dispatch, { departmentParams: params }, signal),
        onError: queryErrorHandler('Error loading departments.'),
        enabled: enabled ?? true,
    });

    return query;
};

export const useHandleDepartmentUpsert = () => {
    const result = useMutation<IDepartment, Error, { upsert : IDepartmentUpsert; type : UpsertTypeEnum }>({
        mutationKey: [UPSERT_DEPARTMENT_KEY],
        mutationFn: (args : { upsert : IDepartmentUpsert; type : UpsertTypeEnum }) => DepartmentHttpService.upsert(args.upsert, args.type),
        onError: queryErrorHandler('Error creating/updating Department.'),
        onSuccess: queryMasterDataSuccessHandler([GET_DEPARTMENTS_KEY], 'Department updated successfully.', DEPARTMENT_TABLE_NAME),
    });

    return result;
};

export const useHandleDepartmentDelete = () => {
    const result = useMutation<void, Error, number>({
        mutationKey: [DELETE_DEPARTMENT_KEY],
        mutationFn: (id : number) => DepartmentHttpService.delete(id),
        onError: queryErrorHandler('Error deleting Department.'),
        onSuccess: queryDeleteMasterDataSuccessHandler([GET_DEPARTMENTS_KEY], 'Department inactivated successfully.', DEPARTMENT_TABLE_NAME),
    });

    return result;
};